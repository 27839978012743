import React from "react";
import FujinLogo from "./FujinLogo.png"

function Documentation(props) {
  return (
    <React.Fragment>
      <div className="home_landing">
        <div >
          <div className="row" >
            <div className="col-md-10 col-xl-10">
              <div className="text_center paddingStyle">
                <img src={FujinLogo} alt="FujinLogo" ></img>
                <h3 className="text_center red_text">Fujin Documentation</h3>
              </div>
            </div>
          </div>
          <div>
            <div className="row fontSize paddingStyle">
              <div className="col-sm-12 col-md-12 col-xl-12">

                <p>
                  You can submit a run in the submit jobs page. You should upload the zip file containing the OpenFOAM case in the upload section. 
                </p>
                <p>
                  You can get more information about the fields if you hover over the information button, i, to the left of the fields’ names. 
                </p>
                <p>
                  The Job Number field is used to tag the computational resource for costing. 
                </p>
                <p>
                  Run type is divided into Mesh, Solve and “Mesh & Solve” where they require mesh.sh (for meshing commands), solve.sh (for solving) and run.sh (for mesh and solve), respectively. 
                </p>
                <p>
                  You can select number of cores and priority from drop down menu <sup><a href="#footnote-1">1</a></sup>. Priority has a direct impact on the time and cost; hence, if not urgent, it is recommended to use the default “Low priority” mode <sup><a href="#footnote-2">2</a></sup>.
                </p>

              </div>
            </div>
          </div>
        </div>
        <footer className="font20Size">
          <ol>
            <li id="footnote-1">
              <p>This is limited to 64 for testing purposes.</p>
            </li>
            <li id="footnote-2">
              <p> High and Low priority modes correspond to AWS on-demand and spot instances, respectively. AWS spot instances represent AWS's excess capacity; Since Spot uses the spare capability it is cheaper than On-Demand price but if the capacity is full, it might take time to find the computational resource to run the job. </p>
            </li>
          </ol>
        </footer>
      </div>
    </React.Fragment>
  );
}

export default Documentation;