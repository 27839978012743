import "@arc-web/components/dist/components/sidebar/arc-sidebar.js";
import { useNavigate } from "react-router-dom";

function Sidebar() {
    const navigate = useNavigate()

    return (
        <arc-sidebar slot="side">
            <arc-menu>
                <arc-menu-item value="home">
                    <arc-icon name="home" slot="prefix"></arc-icon>
                    <arc-button 
                        type="tab" 
                        color="primary" 
                        size="medium" 
                        onClick={() => navigate("/")}
                        >
                        Home
                    </arc-button>
                </arc-menu-item>
                <arc-menu-item value="submit">
                    <arc-icon name="note" slot="prefix"></arc-icon>
                    <arc-button
                        type="tab"
                        color="primary"
                        size="medium"
                        onClick={() => navigate("/submit")}
                    >
                        Submit Jobs
                    </arc-button>
                </arc-menu-item>
                <arc-menu-item value="view">
                    <arc-icon name="eye" slot="prefix"></arc-icon>
                    <arc-button
                        type="tab"
                        color="primary"
                        size="medium"
                        onClick={() => navigate("/jobs")}
                    >
                        Jobs
                    </arc-button>
                </arc-menu-item>
            </arc-menu>
        </arc-sidebar>
    )
}

export default Sidebar;