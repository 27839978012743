import React, { Component } from "react";
import { Button, Col, InputGroup, Form, Tabs, Tab } from "react-bootstrap";
import axios from "axios";
import moment from "moment";
import { ModuleError } from "../components";
import "@arc-web/components/dist/components/button/arc-button.js";
import "@arc-web/components/dist/components/tooltip/arc-tooltip.js";
import "@arc-web/components/dist/components/icon/arc-icon.js";
import { useNavigate } from 'react-router-dom';
import { FileExplorer } from "../components";
import { isEmpty } from "lodash";
import { acquireAccessToken, isTokenExpiredlocalStorage, getLocalToken } from '../services/utils';
import { withMsal } from "@azure/msal-react";

const MyComponentWrapper = () => {
  const navigate = useNavigate();
  const redirectToAnotherPage = (url) => {
    if (url) {
      navigate(url);
    }
    else {
      navigate('/jobs');
    }
  };
  return <Submit redirectToAnotherPage={redirectToAnotherPage} />;
};

class Submit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileToUpload: undefined,
      success: undefined,
      error: undefined,
      clustername: undefined,
      multirun: undefined,
      runType: "1",
      core: "1",
      priority: "1",
      jobNumber: "",
      runName: "",
      zippedFileStructure: [],
      validated: true,
      status: "",
      isLoading: false,
      isSubmitting: false,
      readyToRun: false,
      fileContent: '',
      editMode: false,
      tabKey: 'Basic',
      keepCluster: false,
      dcv: false,
      existing_headnode_id: undefined,
      enableMaxRunTime: true,
      maxRunTime: '10',
      selectedFile: undefined,
      token: undefined,
      AccessToken: undefined,
    };
  }

  componentDidMount() {
    let t = getLocalToken();
    this.setState({
      token: t,
      AccessToken: t.AccessToken,
    });
    // if (this.state.token){
    //   this.state = {
    //     token: t
    //     AccessToken: t.AccessToken,
    //   }; 
    // }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.token) {
      let t = getLocalToken();
      this.setState({
        token: t,
        AccessToken: t.AccessToken,
      });
    }
    else if (this.state.token && this.state.token.AccessToken && isTokenExpiredlocalStorage(this.state.AccessToken)) {
      console.log('Token has expired.');
      this.handleAcquireToken();
    }
  }

  async handleAcquireToken() {
    try {
      let t = await acquireAccessToken();
      this.setState({
        token: t,
        AccessToken: t.accessToken,
      });
    } catch (error) {
      console.error('Error:', error);
      localStorage.clear();
      window.location.reload();
    }
  }

  getUser() {
    var values = [];
    let name = false;
    let username = false;
    if (
      this.props &&
      this.props.userDetails &&
      this.props.userDetails.name &&
      this.props.userDetails.username
    ) {
      name = this.props.userDetails.name;
      username = this.props.userDetails.username;
    }

    if (!name && !username) {
      const lStorage = { ...localStorage };
      values = Object.values(lStorage);
      for (let i in values) {
        if (
          values[i] &&
          typeof values[i] === "string" &&
          values[i] !== "undefined"
        ) {
          try {
            let objs = JSON.parse(values[i]);
            if (objs && objs.authorityType && objs.authorityType === 'MSSTS' && objs.name && objs.username) {
              name = objs.name
              username = objs.username
            }
          } catch (e) { }
        }
      }
    }
    return { name: name, username: username };
  }

  uploadFile() {
    const { runName, jobNumber, token, AccessToken } = this.state;
    if (!token || (token && AccessToken && isTokenExpiredlocalStorage(AccessToken))) {
      this.handleAcquireToken();
    }
    this.setState({ status: "Running", isDesabled: false });
    let clustername = `${runName}-${jobNumber}-${moment().format(
      "YYYY-MM-DD-hh-mm-ss"
    )}`;
    this.setState({
      zippedFileStructure: [],
    });

    axios({
      method: "GET",
      url: `${process.env.REACT_APP_BASEURL}/upload?fileName=${clustername}/${this.state.fileToUpload.name}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token.idToken,
      },
    })
      .then((response) => {
        const url = response.data;
        axios({
          method: "PUT",
          url: url,
          data: this.state.fileToUpload,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((res) => {
            this.setState({
              success: "OpenFOAM case zipped file uploaded successfully",
              error: undefined,
              clustername: clustername,
            });
            const scriptlocation = "runSlurm.sh"
            axios({
              method: "GET",
              url: `${process.env.REACT_APP_BASEURL}/upload/validate?fileName=${this.state.fileToUpload.name}&scriptlocation=${scriptlocation}&cluster_name=${clustername}&runType=${this.state.runType}`,
              headers: {
                "Content-Type": "application/json",
                Authorization: token.idToken,
              },
            })
              .then((response) => {
                let errMSG = undefined
                let successMSG = "OpenFOAM case zipped file uploaded and validated."
                let isReady = true
                if (response && response.data && response.data.zipfile_structure) {
                  if (response.data.well_structured && response.data.poorly_structured) {
                    if (response.data.poorly_structured.length > 0) {
                      errMSG = `OpenFOAM case zipped file validation Error : ${response.data.poorly_structured.join(',')}`
                      successMSG = undefined
                      isReady = false
                    } else if (response.data.well_structured.length > 0) {
                      successMSG = `OpenFOAM case zipped file uploaded and validated for ${response.data.well_structured.join(',')}`
                      this.setState({
                        multirun: response.data.well_structured,
                        keepCluster: true
                      });
                    }
                  }

                  console.log(typeof response.data.zipfile_structure)

                  this.setState({
                    zippedFileStructure: response.data.zipfile_structure,
                    readyToRun: isReady,
                    success: successMSG,
                    error: errMSG,
                  });
                } else {
                  this.setState({
                    success: undefined,
                    error: "OpenFOAM case zipped file uploaded and validatation issue.",
                  });
                }
              })
              .catch((err) => {
                this.setState({
                  error: `OpenFOAM case zipped file validation Error : ${err.response.data}`,
                  success: undefined,
                  isLoading: false,
                });
                return err;
              });
          })
          .catch((err) => {
            let message = err.response.data.message || err.message
            this.setState({
              error: `Error occured during uploading the file - ${message}`,
              success: undefined,
              isLoading: false,
            });
            return err;
          });
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.statusCode === '401') {
          this.setState({
            error: err.response.data.message,
            success: undefined,
          });
          localStorage.clear();
          window.location.reload();
        }
        else {
          this.setState({
            error: err.message,
            success: undefined,
          });
        }
      });
  }

  startRun = async () => {
    this.setState({
      isLoading: true,
    });
    this.uploadFile();
  };

  prepareRun = () => {
    const { token, AccessToken } = this.state;
    if (!token || (token && AccessToken && isTokenExpiredlocalStorage(AccessToken))) {
      this.handleAcquireToken();
    }
    let user = this.getUser();
    if (!user) {
      this.setState({
        error: "Can't load user and username",
        success: undefined,
      });
      return
    }
    this.setState({
      isSubmitting: true,
      success: "Requesting a server to start the run"
    });
    const { runName, runType, jobNumber, core, priority, fileToUpload, clustername, keepCluster, existing_headnode_id, enableMaxRunTime, maxRunTime, multirun, dcv } = this.state;
    let data = {
      CLUSTER_NAME: clustername,
      SCRIPTLOCTION: "runSlurm.sh",
      REGION: "ap-southeast-2",
      RUN_NAME: runName,
      JOB_NUMBER: jobNumber,
      FILE_NAME: fileToUpload.name,
      RUN_TYPE: runType,
      NUMBER_OF_CORE: core,
      PRIORITY: priority,
      USER: user.name,
      EMAIL: user.username,
      KEEP_CLUSTER: keepCluster,
      ENABLE_MAX_RUN_TIME: enableMaxRunTime,
      MAX_RUN_TIME: maxRunTime,
      ENABLE_DCV_ACCESS: dcv
    };
    if (existing_headnode_id) {
      data['EXISTING_HN_ID'] = existing_headnode_id
    }
    if (multirun && multirun.length > 0) {
      data['MULTIPLE_RUN'] = multirun
      data['KEEP_CLUSTER'] = true
      // this.run(creds, data)
      this.run(token, data, `/job/${jobNumber}`)
    }
    else {
      this.run(token, data, `/run/${clustername}`)
    }
  };

  run = (token, data, redirectUrl) => {
    this.setState({
      success: "Request submitted and waiting for a server"
    });
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASEURL}/run_simulation`,
      data: data,
      headers: {
        "Content-Type": "application/json",
        Authorization: token.idToken,
      },
    })
      .then((res) => {
        if (res.status === 202 && res.data && res.data.InstanceId) {
          this.setState({
            existing_headnode_id: res.data.InstanceId,
            error: undefined,
            success: `HeadNode is ${res.data.InstanceId}. It will be ready to use shortly. Stay tuned.`,
          })

          data['EXISTING_HN_ID'] = res.data.InstanceId

          this.retryRunApiCall(token, data, redirectUrl);
        }
        else {
          this.setState({
            isSubmitting: false,
            existing_headnode_id: undefined
          });
          this.setState({
            success: "Request submitted successfully",
            error: undefined,
            isSubmitting: false,
          });
          const { redirectToAnotherPage } = this.props;
          redirectToAnotherPage(redirectUrl)
        }
      })
      .catch((err) => {
        console.log(err)
        this.setState({
          isSubmitting: false,
        });
        if (err.response) {
          if ((err.response.data && err.response.data.statusCode === '504') || (err.response.status === '504')) {
            const { redirectToAnotherPage } = this.props;
            redirectToAnotherPage(redirectUrl)
          }
          else if ((err.response.data && err.response.data.statusCode === '401') || (err.response.status === '401')) {
            this.setState({
              error: err.response.data.message,
              success: undefined,
              isSubmitting: false,
            });
            localStorage.clear();
            window.location.reload();
          }
          else {
            let msg = ''
            msg = (err.response && err.response.data) ? err.response.data : err.message
            this.setState({
              error: msg,
              success: undefined,
              isSubmitting: false,
            });
          }
        }
        else {
          if (err.response && (Number(err.response.status) === 504)) {
            const { redirectToAnotherPage } = this.props;
            redirectToAnotherPage(redirectUrl)
          }
          else {
            console.log('Error', err.message);
            let message = err.message;
            this.setState({
              error: `Error Occured while submitting request to create a cluster - ${message}`,
              success: undefined,
              isSubmitting: false,
            });
          }

        }
      });
  }

  retryRunApiCall = (token, data, redirectUrl) => {
    setTimeout(() => {
      this.run(token, data, redirectUrl);
    }, 20000); // Retry after 20 seconds (adjust as needed)
  };

  termination = () => {
    const { clustername, token, AccessToken } = this.state;
    if (!token || (token && AccessToken && isTokenExpiredlocalStorage(AccessToken))) {
      this.handleAcquireToken();
    }
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASEURL}/termination`,
      data: {
        clustername: clustername,
        region: "ap-southeast-2",
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: token.idToken,
      },
    })
      .then((res) => {
        console.log(res);
        this.setState({
          success: "Cluster termination in progress",
          error: undefined,
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.data && err.response.data.statusCode === '401') {
          this.setState({
            error: `Error : ${err.response.data.message}`,
            success: undefined,
          });
          localStorage.clear();
          window.location.reload();
        }
        else {
          this.setState({
            error: `Error : ${err.response.data.message}`,
            success: undefined,
          });
        }
      });
  };

  setKey = (k, e) => {
    this.setState({
      tabKey: k,
    });
  };

  openFilehandleCallbackFunc = (data, file) => {
    this.setState({
      selectedFile: file,
    });
  };

  basicForm = () => {
    return (
      <div className="row">
        <div className="col-md-6">
          <Form.Row>
            <Form.Group
              as={Col}
              md="10"
              controlId="validationCustom03"
            >
              <Form.Label>
                <arc-tooltip
                  placement="top"
                  delay="150"
                  trigger="hover"
                  hoist=""
                  content="Run Name"
                >
                  <arc-icon name="info"></arc-icon>
                  <> </> Run Name
                </arc-tooltip>

                <> </>
              </Form.Label>
              <Form.Control
                onChange={(e) => {
                  this.setState({
                    runName: e.target.value.replace(
                      /[^a-zA-Z0-9-]/g,
                      ""
                    ),
                  });
                }}
                type="text"
                placeholder="Please enter run name."
                isValid={this.state.runName !== ""}
                isInvalid={this.state.runName === ""}
                value={this.state.runName}
                ref={(el) => (this.el = el)}
                required
              />

              <Form.Control.Feedback type="invalid">
                Please provide a Run Name. Please don't use special
                character such as ~ ! @ # $ % ^ & * ( )
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group
              as={Col}
              md="10"
              controlId="validationCustom03"
            >
              <Form.Label>
                <arc-tooltip
                  placement="top"
                  delay="150"
                  trigger="hover"
                  hoist=""
                  content="The Job Number field is used to tag the computational resource for costing. "
                >
                  <arc-icon name="info"></arc-icon>
                  <> </>Job Number
                </arc-tooltip>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Please enter job number"
                isValid={this.state.jobNumber !== ""}
                isInvalid={this.state.jobNumber === ""}
                value={this.state.jobNumber}
                ref={(el) => (this.el = el)}
                required
                onChange={(e) => {
                  this.setState({
                    jobNumber: e.target.value.replace(
                      /[^a-zA-Z0-9-]/g,
                      ""
                    ),
                  });
                }}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a Run Number.
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
        </div>
        <div className="col-md-4">
          <Form.Row>
            <Form.Group
              as={Col}
              md="10"
              controlId="validationCustom02"
            >
              <Form.Label>
                <arc-tooltip
                  placement="top"
                  delay="150"
                  trigger="hover"
                  hoist=""
                  content="You can select number of cores from drop down menu"
                >
                  <arc-icon name="info"></arc-icon>
                  <> </>No. Cores
                </arc-tooltip>
              </Form.Label>
              <Form.Control
                as="select"
                required
                value={this.state.core}
                onChange={(e) => {
                  this.setState({ core: e.target.value });
                }}
                placeholder="Please select number of cores"
                ref={(el) => (this.el = el)}
              >
                <option value={1}>16</option>
                <option value={2}>32</option>
                <option value={3}>64</option>
                <option value={4}>128</option>
                <option value={5}>192</option>
                <option value={6}>256</option>
                <option value={7}>320</option>
                <option value={8}>384</option>
                <option value={9}>448</option>
                <option value={10}>512</option>
              </Form.Control>

              <Form.Control.Feedback type="invalid">
                Please provide a valid Core number.
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="10">
              <Form.Label>
                <arc-tooltip
                  placement="top"
                  delay="150"
                  trigger="hover"
                  hoist=""
                  content="Run type is divided into Mesh, Solve and “Mesh & Solve” where they require mesh.sh (for meshing commands), solve.sh (for solving) and run.sh (for mesh and solve), respectively. "
                >
                  <arc-icon name="info"></arc-icon>
                  <> </>Run type
                </arc-tooltip>
              </Form.Label>
              <Form.Control
                as="select"
                required
                value={this.state.runType}
                onChange={(e) => {
                  this.setState({ runType: e.target.value });
                }}
                placeholder="Please select run type"
                ref={(el) => (this.el = el)}
              >
                <option value={1}>Mesh & Solve</option>
                <option value={2}>Mesh</option>
                <option value={3}>Solve</option>
              </Form.Control>
              {this.state.runType ? (
                ""
              ) : (
                <div className="invalid-feedback">
                  invalid select
                </div>
              )}
            </Form.Group>
          </Form.Row>
        </div>
      </div>
    )
  }

  btn = (readyToRun, IsDisabledRun, multirun) => {
    return (
      <div className="card-header">
        <div className="row">
          <div className="col">
            <h4>Submit Job</h4>
          </div>
          {readyToRun ? (
            <>
              {multirun ? (
                <div className="termination_btn">
                  <Button
                    key="SubmitAll"
                    variant="outline-success"
                    disabled={IsDisabledRun || this.state.isSubmitting}
                    onClick={(e) => this.prepareRun(multirun)}
                    size="lg"
                  >
                    {this.state.isSubmitting ? "Preparing Cluster!" : `Submit ${multirun.length} run`}
                  </Button>{" "}
                  {"    "}
                </div>

              ) : (
                <div className="termination_btn">
                  <Button
                    key="Submit"
                    variant="outline-success"
                    disabled={IsDisabledRun || this.state.isSubmitting}
                    onClick={(e) => this.prepareRun()}
                    size="lg"
                  >
                    {this.state.isSubmitting ? "Preparing Cluster!" : "Submit"}
                  </Button>{" "}
                  {"    "}
                </div>
              )}
            </>
          ) : (
            <div className="termination_btn">
              <Button
                key="upload"
                variant="outline-success"
                disabled={IsDisabledRun || this.state.isLoading}
                onClick={(e) => this.startRun()}
                size="lg"
              >
                {this.state.isLoading ? "Validating!" : "Upload"}
              </Button>{" "}
              {"    "}
            </div>
          )}
        </div>
      </div>
    )
  }

  handleSwitchChange = () => {
    const newValue = this.state.priority === "1" ? "2" : "1";
    this.setState({ priority: newValue });
  }

  handleUpload = (event, folder) => {
    this.setState({
      fileToUpload: event.target.files,
    })
  }

  render() {
    const { runName, jobNumber, core, fileToUpload, readyToRun, tabKey, priority, enableMaxRunTime, maxRunTime, multirun, dcv } = this.state;
    const IsDisabledRun = runName === "" || jobNumber === "" || core === "" || fileToUpload === undefined;
    return (
      <React.Fragment>
        <div className="viewpage_landing">
          <div className="row">
            <div className="col" sm={12}>
              {this.state.error && (
                <ModuleError message={this.state.error} variant="danger" />
              )}
              {this.state.success && (
                <ModuleError message={this.state.success} variant="success" />
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-8 col-xl-8">

              <Tabs
                activeKey={tabKey}
                onSelect={(k, e) => this.setKey(k, e)}
                className="mb-3"
              >
                <Tab eventKey="Basic" title="Basic">
                  <div className="card">
                    {this.btn(readyToRun, IsDisabledRun, multirun)}
                    <div className="card-body">
                      <Form noValidate validated={this.state.validated}>
                        {this.basicForm()}
                      </Form>
                    </div>
                  </div>

                </Tab>
                <Tab eventKey="Adavnce" title="Adavnce">
                  <div className="card">
                    {this.btn(readyToRun, IsDisabledRun, multirun)}
                    <div className="card-body">
                      <Form noValidate validated={this.state.validated}>
                        {this.basicForm()}
                        <div className="row">
                          <div className="col-md-6">
                            <Form.Row>
                              <Form.Group
                                as={Col}
                                md="10"
                                controlId="validationCustom04"
                              >
                                <Form.Label>

                                  <arc-tooltip
                                    placement="top"
                                    delay="150"
                                    trigger="hover"
                                    hoist=""
                                    content="Donot terminate the cluster automatically. "
                                  >
                                    <arc-icon name="info"></arc-icon>
                                    <> </>Keep/Terminate Cluster
                                  </arc-tooltip>
                                </Form.Label>
                                <Form.Check
                                  type="switch"
                                  id="keepCluster-switch"
                                  label={this.state.keepCluster ? "Keep Cluster" : "Terminate Cluster"}
                                  onChange={(e) => {
                                    this.setState({ keepCluster: e.target.checked });
                                  }}
                                  checked={this.state.keepCluster}
                                />
                              </Form.Group>
                            </Form.Row>

                          </div>
                          <div className="col-md-4">
                            <Form.Row>
                              <Form.Group as={Col} md="10">
                                <Form.Label>
                                  <arc-tooltip
                                    placement="top"
                                    delay="150"
                                    trigger="hover"
                                    hoist=""
                                    content="Priority has a direct impact on the time and cost; hence, if not urgent, it is recommended to use the default “Low priority” mode"
                                  >
                                    <arc-icon name="info"></arc-icon>
                                    <> </>Priority
                                  </arc-tooltip>
                                </Form.Label>
                                <Form.Check
                                  type="switch"
                                  id="priority-switch"
                                  label={priority === '2' ? 'High' : 'Low'}
                                  onChange={this.handleSwitchChange}
                                  checked={priority === '2'}
                                />
                              </Form.Group>
                            </Form.Row>
                          </div>

                          <div className="col-md-6">
                            <Form.Row>
                              <arc-tooltip
                                placement="top"
                                delay="150"
                                trigger="hover"
                                hoist=""
                                content="Limit the max simulation run time"
                              >
                                <arc-icon name="info"></arc-icon>
                                <> </>Maximum Run time (Hours)
                              </arc-tooltip>
                              <InputGroup className="mb-3">
                                <InputGroup.Checkbox
                                  id="max-sim-hours-switch"
                                  aria-label="Checkbox for following text input"
                                  checked={enableMaxRunTime}
                                  onChange={(e) => {
                                    this.setState({ enableMaxRunTime: e.target.checked });
                                  }}
                                />
                                <Form.Control
                                  onChange={(e) => {
                                    this.setState({
                                      maxRunTime: e.target.value.replace(
                                        /[^0-9]/g,
                                        ""
                                      ),
                                    });
                                  }}
                                  type="text"
                                  placeholder="Max Run Time - Hours"
                                  disabled={enableMaxRunTime}
                                  value={maxRunTime}
                                  ref={(el) => (this.el = el)}
                                />
                              </InputGroup>
                            </Form.Row>
                          </div>

                          <div className="col-md-4">
                            <Form.Row>
                              <Form.Group as={Col} md="10">
                                <Form.Label>
                                  <arc-tooltip
                                    placement="top"
                                    delay="150"
                                    trigger="hover"
                                    hoist=""
                                    content="Access to Head Node via DCV. In order to access to DCV, HeadNode instance should created in Public Subnet"
                                  >
                                    <arc-icon name="info"></arc-icon>
                                    <> </>Access via DCV
                                  </arc-tooltip>
                                </Form.Label>
                                <Form.Check
                                  type="switch"
                                  id="priority-dcv"
                                  label={dcv ? "Required" : "Not Required. "}
                                  onChange={(e) => {
                                    this.setState({ dcv: e.target.checked });
                                  }}
                                  checked={dcv}
                                />
                              </Form.Group>
                            </Form.Row>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
            <div className="col-md-4 col-xl-4">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Upload zipped OpenFOAM case</h4>
                </div>
                <div className="card-body">
                  <Form noValidate>
                    <Form.Row>
                      <InputGroup className="mb-3">
                        <div className="custom-file">
                          <Form.Control
                            aria-describedby="custom-addons5"
                            type="file"
                            className="custom-file-input"
                            id="validatedCustomFile1"
                            onChange={(e) => {
                              this.setState({
                                fileToUpload: e.target.files[0],
                              });
                            }}
                          />
                          <Form.Label
                            className="custom-file-label"
                            htmlFor="validatedCustomFile1"
                          >
                            Choose file
                          </Form.Label>
                        </div>
                      </InputGroup>
                    </Form.Row>
                    {this.state.fileToUpload ? (
                      <div>
                        <p>Filename: {this.state.fileToUpload.name}</p>
                        <p>Size in bytes: {this.state.fileToUpload.size}</p>
                        <p>
                          lastModifiedDate:{" "}
                          {this.state.fileToUpload.lastModifiedDate.toLocaleDateString()}
                        </p>
                      </div>
                    ) : null}
                  </Form>
                </div>
              </div>
              <hr />
            </div>
          </div>
          <hr />
          {(this.state.zippedFileStructure.length > 0) && (
            <div className="viewpage_landing">
              <div className="card">
                <div className="card-header">
                  <div className="col">
                    <h4>Uploaded zipped OpenFOAM</h4>
                  </div>
                </div>
                <div className="card-body" >
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    {this.state.zippedFileStructure.map((item, index) => (
                      <FileExplorer key={index} data={[item]} props={this.props} clustername={isEmpty(this.state.multirun) ? this.state.clustername : this.state.clustername + "-" + this.state.multirun[index]} />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default withMsal(MyComponentWrapper);