

import { useState, useEffect } from "react";
import axios from "axios";
import { msalInstance } from "../index.js";
import { loginRequest } from "../services/authConfig";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import jwt_decode from 'jwt-decode';

export const callApi = async (method, url, data = null, headers = {}) => {
    try {
        const config = {
            method,
            url,
            data,
            headers,
        };

        const response = await axios(config);
        return response.data;
    } catch (error) {
        console.error('API call error:', error);
        throw error;
    }
};

export function isTokenExpiredlocalStorage(token) {
    if (!token) return true;

    const decodedToken = jwt_decode(token);
    // const jwtToken = JSON.parse(atob(token.split('.')[1]));
    const expiration = decodedToken.exp * 1000;
    const now = new Date().getTime();
    return now >= expiration;
}

export function isTokenExpired(token) {
    if (!token) return true;

    // const jwtToken = JSON.parse(atob(token.split('.')[1]));
    const expiration = token.expiresOn * 1000;
    const now = new Date().getTime();
    return now >= expiration;
}

export async function getCred() {
    // const { login, result, loginError } = useMsalAuthentication(InteractionType.Silent, loginRequest);
    var values = [];
    let idToken = false;
    let AccessToken = false;
    let isTokenExp = true
    const lStorage = { ...localStorage };
    values = Object.values(lStorage);
    for (let i in values) {
        if (values[i] && typeof values[i] === "string" && values[i] !== "undefined") {
            try {
                let objs = JSON.parse(values[i]);
                if (objs && objs.credentialType) {
                    if (objs.credentialType === "IdToken" && objs.secret) {
                        idToken = objs.secret;
                    } else if (objs.credentialType === "AccessToken" && objs.secret) {
                        AccessToken = objs.secret;
                        isTokenExp = isTokenExpired(AccessToken)
                    }
                }
            } catch (e) {
                // console.log(e);
            }
        }
    }

    if (isTokenExp) {
        // NOT WORKING
        const account = msalInstance.getActiveAccount();
        if (!account) {

            // login(InteractionType.Silent, loginRequest);
            // login(InteractionType.Popup, loginRequest);
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }
        try {
            
            return msalInstance.acquireTokenSilent({
                ...loginRequest,
                account: account
            })
                .then((response) => {


                }).catch(async (e) => {
                    const tokenRequest = {
                        account: account, // This is an example - Select account based on your app's requirements
                        scopes: ["openid", "profile", "User.Read"]
                    }
                    await msalInstance.acquireTokenPopup(tokenRequest);

                })
        } catch (error) {
            return;
        }
    } else {
        return { 
            idToken: idToken, 
            AccessToken: AccessToken
        };
    }
};

export function getLocalToken() {
    var values = [];
    let idToken = false;
    let AccessToken = false;
    let isTokenExp = true
    const lStorage = { ...localStorage };
    values = Object.values(lStorage);
    for (let i in values) {
        if (values[i] && typeof values[i] === "string" && values[i] !== "undefined") {
            try {
                let objs = JSON.parse(values[i]);
                if (objs && objs.credentialType) {
                    if (objs.credentialType === "IdToken" && objs.secret) {
                        idToken = objs.secret;
                    } else if (objs.credentialType === "AccessToken" && objs.secret) {
                        AccessToken = objs.secret;
                        isTokenExp = isTokenExpired(AccessToken)
                    }
                }
            } catch (e) {
                // console.log(e);
            }
        }
    }

    if (isTokenExp) {
        return false
    } else {
        return { 
            idToken: idToken, 
            AccessToken: AccessToken
        };
    }
};

export function useApiToken(dependencies) {
    const { instance, inProgress, accounts } = useMsal();
    const [accessToken, setAccessToken] = useState(null);
    const [token, setToken] = useState(null);

    const getAccessToken = async () => {
        if (!token && inProgress === InteractionStatus.None) {
            const accessTokenRequest = {
                scopes: ["openid", "profile", "User.Read"],
                account: accounts[0],
            };

            instance
                .acquireTokenSilent(accessTokenRequest)
                .then((accessTokenResponse) => {
                    setToken(accessTokenResponse)
                    setAccessToken(accessTokenResponse.accessToken);
                })
                .catch((error) => {
                    if (error instanceof InteractionRequiredAuthError) {
                        instance
                            .acquireTokenPopup(accessTokenRequest)
                            .then(function (accessTokenResponse) {
                                setToken(accessTokenResponse)
                                setAccessToken(accessTokenResponse.accessToken);
                            })
                            .catch(function (error) {
                                // Acquire token interactive failure
                                console.log(error);
                                // throw Error("1 - No active account! Verify a user has been signed in and setActiveAccount has been called.");
                            });
                    }
                    console.log(error);
                    // throw Error("2 - No active account! Verify a user has been signed in and setActiveAccount has been called.");
                });
        }
    }

    useEffect(() => {
        console.log('Custom effect is about to run');
        getAccessToken();
    }, dependencies);

    return [token, accessToken, getAccessToken]
};

export const acquireAccessToken = async () => {
    const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    const accounts = msalInstance.getAllAccounts();

    if (!activeAccount && accounts.length === 0) {
        /*
        * User is not signed in. Throw error or wait for user to login.
        * Do not attempt to log a user in outside of the context of MsalProvider
        * ArcSSO : The default logoutRedirect method does not work within an iframe
        * https://arc.arup.com/?path=/docs/components-arcsso--default
        */
          localStorage.clear();
          window.location.reload();
    }
    const request = {
        // scopes: ["User.Read"],
        ...loginRequest,
        account: activeAccount || accounts[0]
    };

    const authResult = await msalInstance.acquireTokenSilent(request);

    return authResult
};

export async function ProtectedComponent() {
    const { instance, inProgress, accounts } = useMsal();
    const [apiData, setApiData] = useState(null);
    const [token, setToken] = useState(null);

    useEffect(() => {
        if (!apiData && inProgress === InteractionStatus.None) {
            const accessTokenRequest = {
                scopes: ["openid", "profile", "User.Read"],
                account: accounts[0],
            };
            instance
                .acquireTokenSilent(accessTokenRequest)
                .then((accessTokenResponse) => {
                    // Acquire token silent success
                    setToken(accessTokenResponse)
                    // return accessTokenResponse
                    // Call your API with token
                    callApi(accessTokenResponse).then((response) => {
                      setApiData(response);
                    });
                })
                .catch((error) => {
                    if (error instanceof InteractionRequiredAuthError) {
                        instance
                            .acquireTokenPopup(accessTokenRequest)
                            .then(function (accessTokenResponse) {
                                // Acquire token interactive success
                                setToken(accessTokenResponse)
                                // Call your API with token
                                  callApi(accessTokenResponse).then((response) => {
                                    setApiData(response);
                                  });
                            })
                            .catch(function (error) {
                                // Acquire token interactive failure
                                console.log(error);
                                throw Error("1 - No active account! Verify a user has been signed in and setActiveAccount has been called.");
                            });
                    }
                    console.log(error);
                    throw Error("2 - No active account! Verify a user has been signed in and setActiveAccount has been called.");
                });
        }
    }, [instance, accounts, inProgress, apiData]);

    return apiData
}