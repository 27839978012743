import React, { useState, useEffect } from "react";
import { Button, Tabs, Tab } from "react-bootstrap";
import { ModuleError, RunTable } from "../components";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import "@arc-web/components/dist/components/spinner/arc-spinner.js";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { isEmpty } from "lodash";
import { getLocalToken, useApiToken } from '../services/utils';


function ViewJobs(props) {
  const navigate = useNavigate();
  const [success, setSuccess] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [jobsTable, setJobsTable] = useState([]);
  const [runsTable, setRunsTable] = useState([]);
  const [isLoadingJobsTable, setIsLoadingJobsTable] = useState(true);
  const [tabKey, setTabKey] = useState("Jobs View");
  const [activeItem, setActiveItem] = useState(null);
  let [token, accessToken, getAccessToken] = useApiToken([]);


  // This useEffect has impact on other useEffect causing getJobData to be called 3 times. 
  useEffect(() => {
    let t = getLocalToken()
    if (t && t.AccessToken) {
      token = t
      accessToken = t.AccessToken
    }
    else {
      const isAccessTokenExpired = !accessToken;
      if (isAccessTokenExpired) {
        getAccessToken();
        console.log("calling isAccessTokenExpired")
      }
    }
  }, [token, accessToken, getAccessToken]);

  useEffect(() => {
    
    if (token) {
      getJobData(token);
    }
    if (!isEmpty(runsTable)) {
      return () => {
        console.log('done')
      } 
    }
  }, [token]);


  const getJobData = async (token) => {

    try {
      if (!token || !token.idToken) {
        setError("Can't load user login details")
        setSuccess(undefined)
        return;
      }
      // clearInterval(intervalId);
      setError(undefined)
      axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASEURL}/viewjobs`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token.idToken,
        },
      })
        .then((res) => {
          res.data && res.data.run_list && setRunsTable(res.data.run_list)
          res.data && res.data.job_list && setJobsTable(res.data.job_list)
          setIsLoadingJobsTable(false)
          setError(undefined)
        })
        .catch((err) => {
          setIsLoadingJobsTable(false)
          if (err.response && err.response.data && err.response.data.statusCode === '401') {
            console.log(err.response)
          }
          else {
            try {
              setError(err.response.data.message)
            } catch (error) {
              setError(err.message)
            }
          }

          // try {
          //   setError(err.response.data.message)
          // } catch (error) {
          //   setError(err.message)
          // }
          
        });
    } catch (error) {
      setError("Can't load jobs")
      setSuccess(undefined)

      return;
    }
  };


  // ---------- Table
  const redirectToAnotherPage = (url) => {
    navigate(url);
  };

  function runActionsFormatter(cell, item) {
    return (
      <div className="col">
        <Button
          variant="outline-success"
          onClick={() => redirectToAnotherPage(`/job/${item.JobNumber}`)}
        >
          View
        </Button>
      </div>
    );
  }

  function costFormatter(item) {
    const duration = moment.duration(moment(item.lastUpdateTime).diff(moment(item.creationTime)))
    const totalSimulationHours = Math.ceil(duration.asHours());
    const cores = parseInt(item.number_of_core)
    const priority = parseInt(item.priority)
    let rate = undefined
    let numberOfComputeNode = 1

    if (cores === 16) {
      rate = priority === 1 ? 0.3 : 0.9;
    }
    else if (cores === 32) {
      rate = priority === 1 ? 0.6 : 1.7;
    }
    else if (cores === 64) {
      rate = priority === 1 ? 1.2 : 3.3;
    }
    else if (cores > 64) {
      numberOfComputeNode = cores / 64
      rate = priority === 1 ? numberOfComputeNode * 1.2 : numberOfComputeNode * 3.3;
    }
    const contingency = 1.2
    const cost = Math.ceil(totalSimulationHours * rate * contingency)
    return cost
  }

  function totalCostFormatter(cell, item) {
    let totalCost = 0
    item && item.value && item.value.map((item, index) => (
      totalCost = totalCost + costFormatter(item)
    ))
    return (
      <span>
        {totalCost} $
      </span>
    );
  }

  const columns = [{
    dataField: 'JobNumber',
    text: 'Job No.',
    filter: textFilter()
  },
  {
    dataField: 'cost',
    text: 'Total Cost',
    sort: true,
    formatter: totalCostFormatter
  },
  {
    dataField: 'Details',
    text: 'Actions',
    formatter: runActionsFormatter
  },];

  const expandRow = {
    onlyOneExpanding: true,
    renderer: (input, rowIndex) => (
      <div className="expansionTextStyle">
        <div className="row" >
          {!isEmpty(input.value) && (
            <RunTable data={input.value} props={props} callgetJobData={getJobData}></RunTable>
          )}
        </div>
      </div>
    ),
    showExpandColumn: true,
    // onlyOneExpanding: true,
    onExpand: (row, isExpand, rowIndex, e) => {
      // console.log(row);
      // console.log(isExpand);
      // console.log(rowIndex);
      // console.log(e);
    },
  };

  const options = {
    // pageStartIndex: 0,
    sizePerPage: 10,
    // hideSizePerPage: true,
    // hidePageListOnlyOnePage: true
  };

  const { SearchBar } = Search;

  const handleItemClick = (item) => {
    setActiveItem(item);
  };


  return (
    <React.Fragment>
      <div className="viewpage_landing">
        <div className="row">
          <div className="col" sm={6} md={6} xl={6}>
            {error && (
              <ModuleError message={error} variant="danger" />
            )}
            {success && (
              <ModuleError message={success} variant="success" />
            )}
          </div>
        </div>
        <div className="row">
          <div className="col" sm={10} md={10} xl={9}>
            {isLoadingJobsTable && (
              <arc-spinner className="apinnerStyle">loading...</arc-spinner>
            )}
            <Tabs
              activeKey={tabKey}
              onSelect={(k, e) => setTabKey(k, e)}
              className="mb-3"
            >
              <Tab eventKey="Jobs View" title="Jobs View">
                <BootstrapTable
                  bootstrap4
                  keyField='JobNumber'
                  data={jobsTable}
                  columns={columns}
                  headerWrapperClasses="headerCells"
                  pagination={paginationFactory(options)}
                  expandRow={expandRow}
                  filter={filterFactory()}
                  striped
                />
              </Tab>
              <Tab eventKey="Runs View" title="Runs View">
                <div className="row">
                  {!isEmpty(runsTable) && (
                    <RunTable data={runsTable} props={props} showSearch={true} callgetJobData={getJobData}></RunTable>
                  )}
                  <hr></hr>
                </div>
              </Tab>
            </Tabs>
          </div>
          <hr></hr>
        </div>
      </div>
    </React.Fragment>
  );
}


export default ViewJobs;