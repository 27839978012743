import React, { useState, useEffect } from "react";
import { Button, Tabs, Tab } from "react-bootstrap";
import axios from "axios";
import { ModuleError } from "../components";
import { isEmpty } from "lodash";
import ReactECharts from "echarts-for-react";
import { v4 as uuid } from "uuid";
import "@arc-web/components/dist/components/button/arc-button.js";
import "@arc-web/components/dist/components/tooltip/arc-tooltip.js";
import "@arc-web/components/dist/components/icon/arc-icon.js";
import { useParams } from "react-router-dom";
import "@arc-web/components/dist/components/spinner/arc-spinner.js";
import { loginRequest } from "../services/authConfig";
import { useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from '@azure/msal-browser';
import { getLocalToken, useApiToken } from '../services/utils';

const overflowStyle = {
  overflow: "scroll",
  height: "2800px",
};

function Run(props) {
  let cname = useParams();
  const [clustername, setClustername] = useState(cname ? cname.clustername : undefined);
  const [success, setSuccess] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [residualsData, setResidualsData] = useState(undefined);
  const [maxminData, setMaxminData] = useState(undefined);
  const [probsData, setProbsData] = useState(undefined);
  const [tabKey, setTabKey] = useState("Residuals");
  const [isCompleted, setIsCompleted] = useState(undefined);
  const [isHNTerminated, setIsHNTerminated] = useState(undefined);
  const [input, setInput] = useState({});
  const [output, setOutput] = useState({});
  const [openFOAMCase, setOpenFOAMCase] = useState({});
  const [outputLogs, setOutputLogs] = useState({});
  const [logs, setLogs] = useState('');
  const [runslurmFile, setRunslurmFile] = useState('');
  const [progressplot, setProgressplot] = useState(undefined);
  const [intervalId, setIntervalId] = useState(undefined);
  const [cfStatus, setCfStatus] = useState(undefined);
  const [isLoadingGN, setIsLoadingGN] = useState(false);
  const [graphicNodeDCVConnection, setGraphicNodeDCVConnection] = useState(localStorage.getItem("graphicNodeDCVConnection")
    ? localStorage.getItem("graphicNodeDCVConnection")
    : undefined);
  const [graphicNodeUsername, setGraphicNodeUsername] = useState(localStorage.getItem("graphicNodeUsername")
    ? localStorage.getItem("graphicNodeUsername")
    : undefined);
  const [graphicNodePassword, setGraphicNodePassword] = useState(localStorage.getItem("graphicNodePassword")
    ? localStorage.getItem("graphicNodePassword")
    : undefined);
  const [dcvHNPassword, setDcvHNPassword] = useState(localStorage.getItem("dcvHNPassword")
    ? localStorage.getItem("dcvHNPassword")
    : undefined);
  const [dcvHDUser, setDcvHDUser] = useState(localStorage.getItem("dcvHDUser")
    ? localStorage.getItem("dcvHDUser")
    : undefined);
  const [cloudformation_stack_name, setCloudformation_stack_name] = useState(undefined);
  let [token, accessToken, getAccessToken] = useApiToken([]);


  useEffect(() => {
    if(token){
      getJobData(token);
    }
    showPlot(clustername)
  }, [token]);

  const getJobData = (token) => {
    clearInterval(intervalId);
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_BASEURL}/viewjobs?clustername=${clustername}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token.idToken,
      },
    })
      .then((res) => {

        if (res && res.data && res.data.length > 0) {
          let item = res.data[0]['status']
          setInput(item);
          if (item && (item.status === "SUCCEEDED" || item.status === "FAILED" || item.status === "Terminated by user" || item.status === "COMPLETED")) {
            setIsCompleted(item.status)
          }
          setOutput({
            status: item.status,
            cluster_name: item.cluster_name,
            link1: item.cluster_name + ".zip",
            // link1: "results.zip",
          })
          setOpenFOAMCase({
            status: item.status,
            cluster_name: item.cluster_name,
            link1: item.file_name,
            // link1: "results.zip",
          })

          res.data[0]['log_files'] && res.data[0]['log_files'].length !== 0 && setOutputLogs({
            status: item.status,
            cluster_name: item.cluster_name,
            log_files: res.data[0]['log_files'],
            link1: "Output Logs",
          })

          res.data[0]['log'] && setLogs(res.data[0]['log'])
          res.data[0]['runslurmFile'] && setRunslurmFile(res.data[0]['runslurmFile'])

          if (item.enable_dcv_access) {
            item.DCVPASS && setDcvHNPassword(item.DCVPASS)
            item.DCVUser && setDcvHDUser(item.DCVUser)

            localStorage.setItem("dcvHDUser", item.DCVUser);
            localStorage.setItem("dcvHNPassword", item.DCVPASS);

          }
          if (item.Cloudformation_stack_name) {
            setCloudformation_stack_name(item.Cloudformation_stack_name)
            getCFSatus(item.Cloudformation_stack_name)
          }
          else {
            getCFSatus(clustername)
          }
        }
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.statusCode === '401') {
          setError(err.response.data.message)
          localStorage.clear();
          window.location.reload();
        }
        else {
          setError(err.message)
        }
      });
  };

  const refresh = () => {
    getJobData(token);
    showPlot(clustername)
  };

  const option = {
    title: {
      text: "Chart-Title",
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
    },
    legend: {
      type: "scroll",
      orient: "vertical",
      right: 0,
      top: 20,
      bottom: 20,
      align: "right",
      data: [],
    },
    grid: {
      left: "5%",
      right: "5%",
      bottom: "10%",
      containLabel: true,
    },
    dataZoom: [
      {
        id: "dataZoomX",
        type: "slider",
        xAxisIndex: [0],
        filterMode: "filter",
      },
    ],
    xAxis: [
      {
        splitLine: {
          lineStyle: {
            type: "dashed",
          },
        },
        name: "Iteration",
        nameLocation: "center",
        nameTextStyle: {
          fontWeight: "bold",
          verticalAlign: "bottom",
        },
        nameGap: 35,
      },
    ],
    yAxis: [
      {
        type: "log",
        splitLine: {
          lineStyle: {
            type: "dashed",
          },
        },
        name: "YYY",
        nameLocation: "center",
        nameTextStyle: {
          fontWeight: "bold",
          verticalAlign: "bottom",
        },
        nameGap: 70,
      },
    ],
    series: [],
  };

  const outLogs = () => {
    return <pre style={{ fontSize: "20px" }}> {logs} </pre>;
  };


  const probsPlots = () => {
    const charts = [];
    for (let key of Object.keys(probsData)) {
      let opt = JSON.parse(JSON.stringify(option));
      opt["title"] = { text: key };
      opt["yAxis"][0]["name"] = key;
      for (var i = 0; i < probsData[key].length; i++) {
        opt["series"].push(probsData[key][i]);
        opt["legend"]["data"].push(probsData[key][i]["name"]);
      }
      opt["xAxis"][0]["data"] = Array.from(
        Array(probsData[key][0].data.length).keys()
      );
      const unique_id = uuid();
      const small_uuid = unique_id.slice(0, 8);
      charts.push(
        <ReactECharts
          key={small_uuid}
          option={opt}
          style={{ height: "500px", width: "90%" }}
        />
      );
    }
    return <div>{charts}</div>;
  };

  const minMaxPlots = () => {
    const charts = [];
    for (let key of Object.keys(maxminData)) {
      let opt = JSON.parse(JSON.stringify(option));
      opt["title"] = { text: key };
      opt["yAxis"][0]["name"] = key;
      for (var i = 0; i < maxminData[key].length; i++) {
        opt["series"].push(maxminData[key][i]);
        opt["legend"]["data"].push(maxminData[key][i]["name"]);
      }
      const unique_id = uuid();
      const small_uuid = unique_id.slice(0, 8);
      charts.push(
        <ReactECharts
          key={small_uuid}
          option={opt}
          style={{ height: "400px", width: "90%" }}
        />
      );
    }
    return <div>{charts}</div>;
  };

  const residualsPlots = () => {
    const charts = [];
    for (let key of Object.keys(residualsData)) {
      let opt = JSON.parse(JSON.stringify(option));
      opt["title"] = { text: key };
      opt["yAxis"][0]["name"] = key;
      for (var i = 0; i < residualsData[key].length; i++) {
        opt["series"].push(residualsData[key][i]);
        opt["legend"]["data"].push(residualsData[key][i]["name"]);
      }
      opt["xAxis"][0]["data"] = Array.from(
        Array(residualsData[key][0].data.length).keys()
      );
      const unique_id = uuid();
      const small_uuid = unique_id.slice(0, 8);
      charts.push(
        <ReactECharts
          key={small_uuid}
          option={opt}
          style={{ height: "500px", width: "90%" }}
        />
      );
    }

    return <div>{charts}</div>;
  };

  const setKey = (k, e) => {
    setTabKey(k)
  };

  const showPlot = (clusterName) => {
    if (!clusterName || !token) {
      return
    }
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_BASEURL}/chart?clustername=${clusterName}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token.idToken,
      },
    }).then((res) => {
      axios({
        method: "GET",
        url: res.data,
        headers: { "Content-Type": "application/json" },
      })
        .then((response) => {
          setResidualsData(response.data.Residuals)
          setMaxminData(response.data.MaxMin)
          setProbsData(response.data.Probs)
          setProgressplot(true)
          setError(undefined);
          // setSuccess("loading progress plots");
        })
        .catch((err) => {
          setResidualsData(undefined)
          setMaxminData(undefined)
          setProbsData(undefined)
          setProgressplot(false)
          // setError("There is no progess plots for this job");
          setSuccess(undefined);
          clearInterval(intervalId);
        });
    });
  };


  useEffect(() => {
    const interval = setInterval(() => {
      if (clustername && token) {
          getJobData(token);
          showPlot(clustername)
      }
    }, 20000);

    cfStatus && console.log(cfStatus.computeFleetStatus)
    if (input && (input.status === "SUCCEEDED" || input.status === "FAILED" || input.status === "Terminated by user"
      || input.status === "COMPLETED" || input.status === "CANCELLED")) {
      clearInterval(interval);
    }

    if (cfStatus && cfStatus.computeFleetStatus === "Terminated") {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
    // }, []);
  }, [isCompleted, cfStatus, token]);

  const runType = (run_type) => {
    switch (run_type) {
      case "1":
        return "Mesh&solve"
      case "2":
        return "Mesh"
      case "3":
        return "Solve"
      default:
        return "Mesh&solve"
    }
  }

  const priorityType = (priority) => {
    switch (priority) {
      case "1":
        return "Low"
      case "2":
        return "High"
      default:
        return "Low"
    }
  }

  const getCFSatus = async (clname) => {
    try {
      // let creds = await getCred();
      if (!token || !token.idToken) {
        setError("Can't load user login details")
        setSuccess(undefined)
        return;
      };
      if (cloudformation_stack_name) {
        clname = cloudformation_stack_name
      }
      axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASEURL}/cluster_status?clustername=${clname}&region=ap-southeast-2`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token.idToken,
        },
      })
        .then((res) => {
          setCfStatus(res.data)
        })
        .catch((err) => {
          if (err.response.status === 404) {
            setCfStatus({
              "cloudFormationStackStatus": "Deleted",
              "computeFleetStatus": "Terminated"
            })
            clearInterval(intervalId)

            setIsCompleted("Terminated")
            setIsHNTerminated(true)
          }
        });
    } catch (error) {
      setError("Can't CloudFOrmation  jobs")
      setSuccess(undefined)
      return;
    }
  };

  const getGraphic = () => {
    const { name, username } = props.userDetails;
    if (!username || !token) {
      setError("Can't load user details");
      setSuccess(undefined);
      return;
    }
    setIsLoadingGN(true);

    axios({
      method: "GET",
      url: `${process.env.REACT_APP_BASEURL}/graphic_status?clustername=${clustername}&username=${username}&region=ap-southeast-2`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token.idToken,
      },
    })
      .then((res) => {
        console.log(res);
        // if status 200 then run below call if 202 then repeat above call again 
        // 

        if (res.status === 200 && res.data && res.data.IP) {
          axios({
            method: "GET",
            url: `${process.env.REACT_APP_BASEURL}/graphic?clustername=${clustername}&username=${username}&IP=${res.data.IP}&region=ap-southeast-2`,
            headers: {
              "Content-Type": "application/json",
              Authorization: token.idToken,
            },
          })
            .then((res) => {
              console.log(res);
              // setGraphicNode(res.data)
              setGraphicNodeDCVConnection(res.data.DCVConnection)
              setGraphicNodeUsername(res.data.username)
              setGraphicNodePassword(res.data.password)
              setIsLoadingGN(false)

              localStorage.setItem("graphicNode", res.data);
              localStorage.setItem("graphicNodeDCVConnection", res.data.DCVConnection);
              localStorage.setItem("graphicNodeUsername", res.data.username);
              localStorage.setItem("graphicNodePassword", res.data.password);
            })
            .catch((err) => {
              console.log(err)
              if (err.response && err.response.data && err.response.data.statusCode === '400') {
                setError(err.response.data.message);
                setSuccess(undefined);
                setIsLoadingGN(false);
              }
              else {
                setError(err.message);
                setSuccess(undefined);
                setIsLoadingGN(false);
              }
            });
        } else {
          retryApiCall();
        }
      })
      .catch((err) => {
        console.log(err)
        if (err.response && err.response.data && err.response.data.statusCode === '400') {
          setError(err.response.data.message);
          setSuccess(undefined);
          setIsLoadingGN(false);
        }
        else {
          setError(err.message);
          setSuccess(undefined);
          setIsLoadingGN(false);
        }
      });
  };

  const retryApiCall = () => {
    setTimeout(() => {
      getGraphic();
    }, 5000); // Retry after 5 seconds (adjust as needed)
  };

  const termination = () => {
    if (!token) {
      return
    }
    let clname = clustername
    if (input && input.multiple_run && input.Cloudformation_stack_name) {
      clname = input.Cloudformation_stack_name
    }
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASEURL}/termination`,
      data: {
        clustername: clname,
        region: "ap-southeast-2",
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: token.idToken,
      },
    })
      .then((res) => {
        console.log(res);
        setError(undefined);
        setSuccess("Cluster termination in progress");
        setIsHNTerminated(true)
      })
      .catch((err) => {
        console.log(err);
        setIsHNTerminated(undefined)

        if (err.response && err.response.data && err.response.data.statusCode === '401') {
          setError(`Error : ${err.response.data.message}`);
          setSuccess(undefined);

          // ArcSSO : The default logoutRedirect method does not work within an iframe
          // https://arc.arup.com/?path=/docs/components-arcsso--default
          localStorage.clear();
          window.location.reload();

        }
        else {
          setError(`Error : ${err.response.data.message}`);
          setSuccess(undefined);
        }
      });
  };

  const download = async (download_url, filename) => {
    let results = await axios({
      url: download_url,
      method: 'GET',
      responseType: 'blob'
    })
    let hidden_a = document.createElement('a');
    hidden_a.href = window.URL.createObjectURL(new Blob([results.data]));
    hidden_a.setAttribute('download', filename);
    document.body.appendChild(hidden_a);
    hidden_a.click();
  };

  const downloadOpenFOAMCase = (clusterName, file_name) => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_BASEURL}/download_results?clustername=${clusterName}&file=${file_name}&type=uploaded_file`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token.idToken,
      },
    })
      .then((res) => {
        let download_url = res.data;
        download(download_url, file_name);
        setError(undefined)
        setSuccess("Downloading")
      })
      .catch((err) => {
        console.log("DOWNLOAD ERR: ", err);
        setError("Can't Download")
        setSuccess(undefined)
      });
  };

  const downloadOutput = (clusterName) => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_BASEURL}/download_results?clustername=${clusterName}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token.idToken,
      },
    })
      .then((res) => {
        let download_url = res.data;
        download(download_url, clustername + '.zip');
        setError(undefined)
        setSuccess("Downloading")
      })
      .catch((err) => {
        console.log("DOWNLOAD ERR: ", err);
        setError("Can't Download")
        setSuccess(undefined)
      });
  };

 
  return (
    <React.Fragment>
      <div className="viewpage_landing">
        <div className="row">
          <div className="col" sm={12}>
            {error && (
              <ModuleError message={error} variant="danger" />
            )}
            {success && (
              <ModuleError message={success} variant="success" />
            )}
          </div>
        </div>

        <div>
          <div className="row">
            <div className="col-md-12 col-xl-12 col-sm-9">
              <div className="card">
                <div className="card-header">
                  <div className="row">
                    <div className="col">
                      <h4>Run details</h4>
                    </div>
                    {!isCompleted && (
                      <div className="termination_btn">
                        <Button
                          key="refresh"
                          variant="outline-info"
                          disabled={isCompleted}
                          onClick={(e) => refresh()}
                          size="lg"
                        >
                          Refresh
                        </Button>{" "}
                        {"    "}
                      </div>
                    )}
                    {!isHNTerminated && (
                      <div className="termination_btn">
                        <Button
                          key="Stop"
                          variant="outline-danger"
                          disabled={isHNTerminated}
                          onClick={(e) => termination()}
                          size="lg"
                        >
                          Terminate
                        </Button>{" "}
                        {"       "}
                      </div>
                    )}

                    <div className="termination_btn">
                      <Button
                        key="graphicNode"
                        variant="outline-info"
                        onClick={(e) => getGraphic(clustername)}
                        size="lg"
                      >
                        Graphic Node Connection
                      </Button>
                    </div>
                    {/* <div className="termination_btn">
                      <Button
                        key="DCVHeadNode"
                        variant="outline-info"
                        onClick={(e) => getGraphic(clustername)}
                        size="lg"
                      >
                        DCV Connection to HeadNode
                      </Button>
                    </div> */}
                  </div>
                </div>

                <div className="tcluster">
                  {isEmpty(input) && (
                    <arc-spinner className="apinnerStyle">loading...</arc-spinner>
                  )}
                  {!isEmpty(input) && (
                    <div className="card">
                      <div className="card-body fontSize">
                        <h5>
                          Run name  <span>{input.run_name}</span>
                        </h5>
                        <h5>
                          Job number  <span>{input.job_number}</span>
                        </h5>
                        <h5>
                          Run Type
                          <span>
                            {runType(input.run_type)}
                          </span>
                        </h5>
                        <h5>
                          No. Cores
                          <span>
                            {input.number_of_core}
                          </span>
                        </h5>
                        <h5>
                          Priority
                          <span>
                            {priorityType(input.priority)}
                          </span>
                        </h5>
                        <h5>
                          Cluster name  <span>{input.cluster_name}</span>
                        </h5>
                        <h5>
                          File  <span>{input.file_name}</span>
                        </h5>
                        <h5>
                          User <span>{input.user}</span>
                        </h5>
                        {input.keep_cluster ? (
                          <h5>
                            Keep Cluster
                          </h5>
                        ) : (
                          <h5>
                            Terminate Cluster
                          </h5>
                        )}
                        {input.max_run_time && (
                          <h5>
                            Max Run Time {input.max_run_time} Hours
                          </h5>
                        )}
                        {input.enable_dcv_access ? (
                          <h5>
                            Access to HeadNode via DCV is enabled
                          </h5>
                        ) : (
                          <h5>
                            No Access to HeadNode via DCV
                          </h5>
                        )}
                        <hr></hr>
                        <h5>
                          Status  <span>{input.status}</span>
                        </h5>
                        <h5>
                          Progresss <span>{input.progresss_run}%</span>
                        </h5>
                        {!isEmpty(cfStatus) && (
                          <div>
                            <hr></hr>
                            <h5>
                              CloudFormation Stack Status  <span>{cfStatus.cloudFormationStackStatus}</span>
                            </h5>
                            <h5>
                              Compute Fleet Status <span>{cfStatus.computeFleetStatus}</span>
                            </h5>
                            {/* {(dcvHDUser && dcvHNPassword) && ( */}
                            {(input.enable_dcv_access && cfStatus.computeFleetStatus === 'RUNNING' && cfStatus.headNode && cfStatus.headNode.publicIpAddress && dcvHDUser && dcvHNPassword) && (
                              <>
                                <h5>
                                  HeadNode IP Address  <span>{cfStatus.headNode.publicIpAddress}</span>
                                </h5>

                                <h5>
                                  Username  <span>{dcvHDUser}</span>
                                </h5>
                                <h5>
                                  Password <span>{dcvHNPassword}</span>
                                </h5>
                              </>

                            )}
                          </div>
                        )}

                        <hr></hr>
                        {!isEmpty(openFOAMCase) && (
                          <div >
                            {openFOAMCase.status ? (
                              <h5>
                                {" "}
                                Download Uploaded OpenFOAM Case:
                                <button className="btnlink"
                                  onClick={() =>
                                    downloadOpenFOAMCase(openFOAMCase.cluster_name, input.file_name)
                                  }
                                >
                                  {openFOAMCase.link1}
                                </button>

                              </h5>
                            ) : (<></>)}
                          </div>
                        )}
                        {!isEmpty(output) && (
                          <div >
                            {output.status && (output.status === "SUCCEEDED" || output.status === "COMPLETED") ? (
                              <h5>
                                {" "}
                                Download Results:
                                <button className="btnlink"
                                  onClick={() =>
                                    downloadOutput(output.cluster_name)
                                  }
                                >
                                  {output.link1}
                                </button>

                              </h5>
                            ) : (<></>)}
                          </div>
                        )}
                        {(!isEmpty(outputLogs) && outputLogs.length !== 0) && (
                          <div >
                            {outputLogs.log_files ? (
                              <h5>
                                {" "}
                                Download Logs:
                                {/* <button className="btnlink"
                                  onClick={() =>
                                    downloadoutputLogs(outputLogs.log_files)
                                  }
                                >
                                  {outputLogs.link1}
                                </button> */}
                                <ul>
                                  {outputLogs.log_files.map((file, index) => (
                                    <li key={index}>
                                      <button className="btnlink" onClick={() => download(file.url, file.filename)}>{file.filename}</button>
                                    </li>
                                  ))}
                                </ul>
                              </h5>
                            ) : (<></>)}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div className="tcluster">

                  {isLoadingGN && (
                    <div>
                      <arc-spinner className="apinnerStyle">loading...</arc-spinner>
                    </div>
                  )}
                  {(graphicNodeDCVConnection && graphicNodeUsername && graphicNodePassword && !isLoadingGN) && (
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title">Graphic Node Details</h4>
                      </div>
                      <div className="card-body fontSize">
                        <h5>
                          DCV Connection  <span>{graphicNodeDCVConnection}</span>
                        </h5>
                        <h5>
                          Username  <span>{graphicNodeUsername}</span>
                        </h5>
                        <h5>
                          Password <span>{graphicNodePassword}</span>
                        </h5>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {(maxminData || probsData || residualsData || logs) && (
          <div className="viewpage_landing1">
            <div className="row">
              <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12">
                <div className="col">
                  <h5>Progress Plots</h5>
                </div>
                <Tabs
                  activeKey={tabKey}
                  onSelect={(k, e) => setKey(k, e)}
                  className="mb-3"
                >

                  {maxminData && (
                    <Tab eventKey="Residuals" title="Residuals">
                      {residualsData &&
                        tabKey === "Residuals" ? (
                        <div className="card">
                          <div className="card-header">
                            <h4 className="card-title">
                              Residuals Simulation Data
                            </h4>
                          </div>
                          <div className="card-body" style={overflowStyle}>
                            <div>{residualsPlots()}</div>
                          </div>
                        </div>
                      ) : (
                        <div>No File</div>
                      )}
                    </Tab>
                  )}


                  {residualsData && (
                    <Tab eventKey="MinMax" title="MinMax">
                      {maxminData &&
                        tabKey === "MinMax" ? (
                        <div className="card">
                          <div className="card-header">
                            <h4 className="card-title">
                              MinMax Simulation Data
                            </h4>
                          </div>
                          <div className="card-body" style={overflowStyle}>
                            <div>{minMaxPlots()}</div>
                          </div>
                        </div>
                      ) : (
                        <div>No File</div>
                      )}
                    </Tab>
                  )}

                  {probsData && (
                    <Tab eventKey="Probs" title="Probs">
                      {probsData &&
                        tabKey === "Probs" ? (
                        <div className="card">
                          <div className="card-header">
                            <h4 className="card-title">
                              Probs Simulation Data
                            </h4>
                          </div>
                          <div className="card-body" style={overflowStyle}>
                            <div>{probsPlots()}</div>
                          </div>
                        </div>
                      ) : (
                        <div>No File</div>
                      )}
                    </Tab>
                  )}
                  {logs && (
                    <Tab eventKey="OutLog" title="Output">
                      {logs &&
                        tabKey === "OutLog" ? (
                        <div className="card">
                          <div className="card-header">
                            <h4 className="card-title">
                              Output
                            </h4>
                          </div>
                          <div className="card-body" style={overflowStyle}>
                            <div>{outLogs()}</div>
                          </div>
                        </div>
                      ) : (
                        <div>No File</div>
                      )}
                    </Tab>
                  )}

                </Tabs>
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
  // }
}

export default Run;