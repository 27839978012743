import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import React, { useEffect, useState  } from "react";
import { useIsAuthenticated, useMsalAuthentication , useMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { ErrorBoundary } from "react-error-boundary";
import Submit from "./pages/Submit";
import Job from "./pages/Job";
import ViewJobs from "./pages/Viewjobs";
import ViewRuns from "./pages/ViewRuns";
import Run from "./pages/Run";
import Home from "./pages/Home";
import Documentation from "./pages/Documentation"
import Unauthenticated from "./pages/Unauthenticated";
import "@arc-web/components/dist/components/container/arc-container.js";
import "@arc-web/components/dist/components/navbar/arc-navbar.js";
import "@arc-web/components/dist/components/sidebar/arc-sidebar.js";
import "@arc-web/components/dist/components/card/arc-card.js";
import "@arc-web/components/dist/components/button/arc-button.js";
import "@arc-web/components/dist/components/sso/arc-sso.js";
import "@arc-web/components/dist/components/bottombar/arc-bottombar.js";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import Sidebar from "./components/Sidebar";
import { loginRequest } from "./services/authConfig";
import { InteractionType, InteractionRequiredAuthError } from '@azure/msal-browser';


const landingStyle = {
  minHeight: "100vh",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "rgba(0,0,0,0.4)",
};

const textStyle = {
  marginTop: "50px",
  textAlign: "center"
};

const iconStyle = {
  color: "rgb(var(--arc-red-060))"
}

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <React.Fragment>
      <div className="row" style={landingStyle}>
        <div className="col" md={8} xl={8}>
          <div className="col">
            <h3 style={textStyle}>
              Oops, there was an error. Please try again!
            </h3>
          </div>
          <div style={textStyle}>
            <arc-button type="outlined" color="primary" size="large" href="/">
              Take me back to my dashboard
            </arc-button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

const UnauthenticatedContent = () => {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        window.location.replace("/");
      }}
    >
      <Routes>
        <Route exact path="/" element={<Unauthenticated />} />
        <Route path="*" element={<Unauthenticated />} />
      </Routes>
    </ErrorBoundary>
  );
};


const AuthenticatedContent = ({ isAuthenticated }) => {
  const { accounts } = useMsal();
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        window.location.replace("/");
      }}
    >
      <Routes>
        <>
          <Route
            exact
            path="/"
            element={<Home userDetails={accounts[0]} />}
          />
          <Route path="/Home" element={<Home userDetails={accounts[0]} />} />
          <Route
            path="/submit"
            element={<Submit userDetails={accounts[0]} />}
          />
          <Route
            path="/run/:clustername"
            element={<Run userDetails={accounts[0]} />}
          />
          <Route
            path="/runs"
            element={<ViewRuns userDetails={accounts[0]} />}
          />
          <Route
            path="/job/:job_number"
            element={<Job userDetails={accounts[0]} />}
          />
          <Route
            path="/jobs"
            element={<ViewJobs userDetails={accounts[0]} />}
          />
          <Route
            path="/documentation"
            element={<Documentation userDetails={accounts[0]} />}
          />
        </>
      </Routes>
    </ErrorBoundary>
  );
};

function App(props) {
  const [isAuthenticated, setIsAuthenticated] = useState(useIsAuthenticated());
  let x = useIsAuthenticated();

  useEffect(() => {
    window.addEventListener("arc-auth", (event) => {
      setIsAuthenticated(true);
    });
  }, []);

  useEffect(() => {
    setIsAuthenticated(x);
  }, [x]);

  const { theme } = props;

  return (
    <BrowserRouter>
        <arc-container theme={theme}>
          <arc-navbar
            slot="nav"
            logo="/assets/fujin_sq_512.svg"
            style={{ height: "6rem", "--logo-height": "3rem" }}
          >
            <arc-button type="tab" href="documentation" target="_blank" ><arc-icon name="book-open" slot="prefix" size="large" style={iconStyle} ></arc-icon></arc-button>
            <span
              slot="name"
              style={{
                fontSize: "26px",
                letterSpacing: "0.00938em",
                color: "#c00000",
                fontWeight: "bold"
              }}
            >
              FUJIN
            </span>
            <arc-sso slot="user" client-id={process.env.REACT_APP_CLIENT_ID} tenant-id={process.env.REACT_APP_TENANT_ID} redirect-uri={process.env.REACT_APP_REDIRECTURI}></arc-sso>
          </arc-navbar>
          {isAuthenticated &&  (<>
              <Sidebar></Sidebar>
              <section
                aria-label={"Upload section"}
                style={{ padding: "var(--arc-spacing-medium" }}
              >
                <arc-card style={{ width: "100%" }}>
                  <AuthenticatedContent />
                </arc-card>
              </section>
            </>)}
          {!isAuthenticated && (
              <section
                aria-label={"Upload section"}
                style={{ padding: "var(--arc-spacing-medium" }}
              >
                <arc-card style={{ width: "100%" }}>
                  <UnauthenticatedContent />
                </arc-card>
              </section>)} 
            </arc-container>
        <arc-bottombar class="bottom-bar"></arc-bottombar>
      </BrowserRouter>
  )
}

function App_authTemplate(props) {
  //__________ EXPERIMENT 1 __________________

  // const isAuthenticated = useIsAuthenticated({scopes: ["openid", "profile", "User.Read"]})
  // const isAuthenticated = useIsAuthenticated()

  // const { instance, inProgress } = useMsal();
  // if (inProgress === InteractionStatus.None && !isAuthenticated) {
  //   instance.loginRedirect(loginRequest);
  // }
  //__________ EXPERIMENT 2 __________________
  // const { login, result, error } = useMsalAuthentication(InteractionType.Silent, loginRequest);

  // useEffect(() => {
  //     if (error instanceof InteractionRequiredAuthError) {
  //         // login(InteractionType.Popup, loginRequest);
  //         login(InteractionType.Silent, loginRequest);
  //     }
  // }, [error]);

  const { theme } = props;
  return (
    <>
      <BrowserRouter>
        <arc-container theme={theme}>
          <arc-navbar
            slot="nav"
            logo="/assets/fujin_sq_512.svg"
            style={{ height: "6rem", "--logo-height": "3rem" }}
          >
            <arc-button type="tab" href="documentation" target="_blank" ><arc-icon name="book-open" slot="prefix" size="large" style={iconStyle} ></arc-icon></arc-button>
            <span
              slot="name"
              style={{
                fontSize: "26px",
                letterSpacing: "0.00938em",
                color: "#c00000",
                fontWeight: "bold"
              }}
            >
              FUJIN
            </span>
            <arc-sso slot="user" client-id={process.env.REACT_APP_CLIENT_ID} tenant-id={process.env.REACT_APP_TENANT_ID} redirect-uri={process.env.REACT_APP_REDIRECTURI}></arc-sso>
          </arc-navbar>
          <AuthenticatedTemplate>
          <Sidebar></Sidebar>
              <section
                aria-label={"Upload section"}
                style={{ padding: "var(--arc-spacing-medium" }}
              >
                <arc-card style={{ width: "100%" }}>
                  <AuthenticatedContent />
                </arc-card>
              </section>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <section
              aria-label={"Upload section"}
              style={{ padding: "var(--arc-spacing-medium" }}
            >
              <arc-card style={{ width: "100%" }}>
                <UnauthenticatedContent />
              </arc-card>
            </section>
          </UnauthenticatedTemplate>
        </arc-container>
        <arc-bottombar class="bottom-bar"></arc-bottombar>
      </BrowserRouter>
    </>
  )
}

export default App;