import React, { useState, useEffect } from 'react';
import { Alert } from 'react-bootstrap';

export const ModuleError = (props) => {
    const [visible, setIsVisible] = useState(false)
    // useEffect(() => {
    //     // message is empty (meaning no errors). Adjust as needed
    //     if(!props.message){
    //      setIsVisible(false)
    //      return
    //     }
    //     // error exists. Display the message and hide after 5 secs
    //     setIsVisible(true)
    //     const timer = setTimeout(() => {
    //       setIsVisible(false)
    //     }, 20000);
    //     return () => clearTimeout(timer);
    //   }, [props.message])

    // if(!visible) return null
    
    return (
        <React.Fragment>
            {props.variant &&
                    <Alert variant={ props.variant}>
                        {props.message}
                        {props.link && 
                            <Alert.Link href={props.link} target="_blank" className="float-right">
                                Demo & Documentation
                            </Alert.Link>
                        }
                    </Alert>
            }
        </React.Fragment>
    );
};