import React, { useState, useEffect } from 'react';
import { AiFillFolder, AiOutlineFolderOpen } from 'react-icons/ai';
import { Form } from "react-bootstrap";
import axios from "axios";
import { ModuleError } from ".";
import { msalInstance } from "../index.js";
import { loginRequest } from "../services/authConfig";
import { useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from '@azure/msal-browser';
import { getCred, getLocalToken, useApiToken } from '../services/utils';

export const FileExplorer = ({ data, props, clustername, indentSize = 20 }) => {
  const [expandedDirectories, setExpandedDirectories] = useState([]);
  const [selectedFile, setSelectedFile] = useState('');
  const [fileContent, setFileContent] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(undefined);
  const [error, setError] = useState(undefined);
  let [token, accessToken, getAccessToken] = useApiToken([]);

  useEffect(() => {
    let t = getLocalToken()
    if (t && t.AccessToken){
      token = t
      accessToken = t.AccessToken
    }
    else {
      const isAccessTokenExpired = !accessToken;
      if (isAccessTokenExpired) {
        getAccessToken();
      }
    }
  }, [token, accessToken, getAccessToken]);

  const readFile = async (file) => {
    try {
      // let creds = await getCred();
      if (!token || !token.idToken) {
        setError("Can't load user login details")
        setSuccess(undefined)
        return;
      }
      setError(undefined);
      try {
        setIsLoading(true)
        let response = await axios({
          url: `${process.env.REACT_APP_BASEURL}/upload/read?cluster_name=${clustername}&path=${file.path}&region=ap-southeast-2`,
          // url:   `${process.env.REACT_APP_BASEURL}/upload_read?cluster_name=${clustername}&path=${file.path}&region=ap-southeast-2`,
          method: 'GET',
          headers: {
            "Content-Type": "application/json",
            Authorization: token.idToken,
          },
        })
        setFileContent(response.data.toString());
        setEditMode(false)
        setIsLoading(false)
      } catch (err) {
        console.log('Error reading file:', err);
        setIsLoading(false)
        if (err.response && err.response.data && err.response.data.statusCode === '401') {
          setError(err.response.data.message)

          // ArcSSO : The default logoutRedirect method does not work within an iframe
          // https://arc.arup.com/?path=/docs/components-arcsso--default
          localStorage.clear();
          window.location.reload();
        }
        else {
          setError(`Error reading file: ${err}`);
        }
      }
    } catch (error) {
      setError("Can't load user login details")
      setSuccess(undefined)
      return;
    }
  };

  const handleChange = (event) => {
    setFileContent(event.target.value);
    setEditMode(true)
  };

  const saveFile = async (file) => {

    getCred().then(creds => {
      setIsLoading(true)
      try {

        axios({
          method: "POST",
          url: `${process.env.REACT_APP_BASEURL}/upload/update`,
          data: {
            CONTENT: fileContent,
            // CONTENT: JSON.stringify({ fileContent }),
            FILE: selectedFile.name,
            PATH: selectedFile.path,
            CLUSTER_NAME: clustername,
            REGION: "ap-southeast-2",
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: creds.idToken,
          },
        })
          .then((res) => {
            // setFileContent(response.data.toString());
            setEditMode(false)
            setIsLoading(false)
          })
          .catch((err) => {
            console.log('Error updating file:', error);
            setIsLoading(false)
            setError(`Error updating file: ${error}`);
          })


        console.log('File saved successfully');
      } catch (err) {
        console.log('Error saving file:', err);
        if (err.response && err.response.data && err.response.data.statusCode === '401') {
          setError(err.response.data.message)

          // ArcSSO : The default logoutRedirect method does not work within an iframe
          // https://arc.arup.com/?path=/docs/components-arcsso--default
          localStorage.clear();
          window.location.reload();

        }
        else {
          setError(`Error reading file: ${err}`);
        }
      }

    }).catch(e => {
      setError("Can't load user login details")
      setSuccess(undefined)
      return;
    })
  };

  const handleDirectoryClick = (directory) => {
    if (expandedDirectories.includes(directory)) {
      setExpandedDirectories(
        expandedDirectories.filter((dir) => dir !== directory)
      );
    } else {
      setExpandedDirectories([...expandedDirectories, directory]);
    }
    setSelectedFile('');
  };

  const handleFileClick = (file) => {
    setSelectedFile(file);
    readFile(file)
  };

  const showFile = () => {
    // return <pre style={{ fontSize: "20px", whiteSpace: 'pre-wrap' }}> {fileContent} </pre>;
    return <Form.Control
      as="textarea"
      rows={20}
      value={fileContent}
      onChange={handleChange}
    // disabled={!editMode}
    />
  };

  const renderTree = (treeData, indentLevel = 0) => {
    return treeData.map((node) => {
      const isDirectory = node.type === 'directory' || node.type === 'zip';
      const isExpanded = expandedDirectories.includes(node.name);
      const paddingLeft = indentLevel * indentSize;

      return (
        <div key={node.name} style={{ paddingLeft }}>
          <div
            onClick={() => (isDirectory ? handleDirectoryClick(node.name) : handleFileClick(node))}
            style={{ cursor: 'pointer' }}
          >
            {isDirectory ? (
              isExpanded ? (
                <AiOutlineFolderOpen style={{ marginRight: '0.5rem' }} />
              ) : (
                <AiFillFolder style={{ marginRight: '0.5rem' }} />
              )
            ) : (
              <span style={{ marginRight: '0.5rem' }}>&nbsp;</span>
            )}
            {node.name}
          </div>
          {isDirectory && isExpanded && renderTree(node.children, indentLevel + 1)}
        </div>
      );
    });
  };

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
          <div className="card">
            {/* <div className="card-header">
              <div className="col">
                <h4>Uploaded zipped OpenFOAM</h4>
              </div>
            </div> */}
            <div className="card-body" >
              <div className="row">
                <div>{renderTree(data)}</div>
              </div>
            </div>
          </div>
        </div>

        {selectedFile && (
          <div className="col-xl-8 col-lg-6 col-md-12 col-sm-10">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col">
                    <h4>{selectedFile.name}</h4>
                  </div>
                  {/* <div className="termination_btn">
                    {editMode && (
                      <Button
                        key="Save"
                        variant="outline-success"
                        // disabled={IsDisabledRun || this.state.isLoading}
                        onClick={saveFile}
                        size="ms"
                      >
                        Save
                      </Button>
                    )}
                  </div> */}
                </div>
              </div>
              <div className="card-body" >
                <div className="row">
                  <div className="col" sm={12}>
                    {error && (
                      <ModuleError message={error} variant="danger" />
                    )}
                    {success && (
                      <ModuleError message={success} variant="success" />
                    )}
                  </div>
                </div>
                {isLoading && (
                  <arc-spinner className="apinnerStyle">loading...</arc-spinner>
                )}
                <div>{showFile()}</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>)
};