const {REACT_APP_CLIENT_ID, REACT_APP_TENANT_ID, REACT_APP_REDIRECTURI} = process.env;
export const msalConfig = {
  auth: {
    clientId: REACT_APP_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${REACT_APP_TENANT_ID}`,
    redirectUri: REACT_APP_REDIRECTURI
  },
  cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: ["openid", "profile", "User.Read"]
};

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};