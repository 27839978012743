import React, { useState, useEffect } from "react";
import { ModuleError, RunTable } from "../components";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import "@arc-web/components/dist/components/spinner/arc-spinner.js";
import { msalInstance } from "../index.js";
import { loginRequest } from "../services/authConfig";
import { useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from '@azure/msal-browser';
import { isEmpty } from "lodash";

function ViewRuns(props) {
  const { login, result, loginError } = useMsalAuthentication(InteractionType.Silent, loginRequest);
  const navigate = useNavigate();
  const [success, setSuccess] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [progressTable, setProgressTable] = useState([]);
  const [isLoadingProgressTable, setIsLoadingProgressTable] = useState(true);

  function isTokenExpired(token) {
    if (!token) return true;
    const jwtToken = JSON.parse(atob(token.split('.')[1]));
    const expiration = jwtToken.exp * 1000;
    const now = new Date().getTime();
    return now >= expiration;
  }

  const getCred = async () => {
    var values = [];
    let idToken = false;
    let isTokenExp = true
    const lStorage = { ...localStorage };
    values = Object.values(lStorage);
    for (let i in values) {
      if (values[i] && typeof values[i] === "string" && values[i] !== "undefined") {
        try {
          let objs = JSON.parse(values[i]);
          if (objs && objs.credentialType) {
            if (objs.credentialType === "IdToken" && objs.secret) {
              idToken = objs.secret;
            } else if (objs.credentialType === "AccessToken" && objs.secret) {
              let AccessToken = objs.secret;
              isTokenExp = isTokenExpired(AccessToken)
            }
          }
        } catch (e) {
          // console.log(e);
        }
      }
    }

    if (isTokenExp) {
      const account = msalInstance.getActiveAccount();
      if (!account) {
        // login(InteractionType.Silent, loginRequest);
        login(InteractionType.Popup, loginRequest);
        // throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
      }
      try {
        return msalInstance.acquireTokenSilent({
          ...loginRequest,
          account: account
        }).then((response) => {


        }).catch(async (e) => {
          const tokenRequest = {
            account: account, // This is an example - Select account based on your app's requirements
            scopes: ["openid", "profile", "User.Read"]
          }
          await msalInstance.acquireTokenPopup(tokenRequest);

        })
      } catch (error) {
        setError("Can't load jobs")
        setSuccess(undefined)
        return;
      }
    } else {
      return { idToken: idToken };
    }
    // if (isTokenExp) {
    //   const account = msalInstance.getActiveAccount();
    //   if (!account) {
    //     login(InteractionType.Silent, loginRequest);
    //     // throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    //   }
    //   return msalInstance.acquireTokenSilent({
    //     ...loginRequest,
    //     account: account
    //   })
    // } else {
    //   return { idToken: idToken };
    // }
  };

  const getJobData = async () => {

    try {
      let creds = await getCred();
      if (!creds || !creds.idToken) {
        setError("Can't load user login details")
        setSuccess(undefined)
        return;
      };
      // clearInterval(intervalId);
      axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASEURL}/viewjobs`,
        headers: {
          "Content-Type": "application/json",
          Authorization: creds.idToken,
        },
      })
        .then((res) => {
          // setProgressTable(res.data)
          res.data && res.data.run_list && setProgressTable(res.data.run_list)
          setIsLoadingProgressTable(false)
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.statusCode === '401') {
            setError(err.response.data.message)

            // ArcSSO : The default logoutRedirect method does not work within an iframe
            // https://arc.arup.com/?path=/docs/components-arcsso--default
            localStorage.clear();
            window.location.reload();

          }
          else {
            setError(err.message)
            setIsLoadingProgressTable(false)
          }
        });

    } catch (error) {
      setError("Can't load jobs")
      setSuccess(undefined)

      return;
    }
  };
  useEffect(() => {
    getJobData();
  }, []);

  return (
    <React.Fragment>
      <div className="viewpage_landing">
        <div className="row">
          <div className="col" sm={6} md={6} xl={6}>
            {error && (
              <ModuleError message={error} variant="danger" />
            )}
            {success && (
              <ModuleError message={success} variant="success" />
            )}
          </div>
        </div>
        <div className="row">
          {!isEmpty(progressTable) && (
            <RunTable data={progressTable} props={props} showSearch={true} callgetJobData={getJobData}></RunTable>
          )}
          <hr></hr>
        </div>
      </div>
    </React.Fragment>
  );
}


export default ViewRuns;