import React from "react";
import FujinLogo from "./FujinLogo.png"
function Unauthenticated() {
  return (
    <React.Fragment>
      <div className="row unauth_landing" >
        <div className="col" md={8} xl={8}>
          <div className="col">
          <div className="text_center paddingStyle">
                <img src={FujinLogo} alt="FujinLogo" ></img>
                
              </div>
              <div className="text_center paddingStyle">
                <h4>Welcome to Fujin</h4>
                <h3 className="text_center red_text">Let Fujin, God of Wind, take you to the clouds.</h3>
                <h4>A web application for running CFD simulations on cloud</h4>
                <h4 className="text_center">Please Login to see more </h4> 
              </div>
          </div>
        </div>
      </div>

    </React.Fragment>
  );
}

export default Unauthenticated;
