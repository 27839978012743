import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import axios from "axios";
import { ModuleError, RunTable } from "../components";
import { isEmpty } from "lodash";
import "@arc-web/components/dist/components/button/arc-button.js";
import "@arc-web/components/dist/components/tooltip/arc-tooltip.js";
import "@arc-web/components/dist/components/icon/arc-icon.js";
import { useParams } from "react-router-dom";
import "@arc-web/components/dist/components/spinner/arc-spinner.js";
import { useApiToken, getLocalToken } from '../services/utils';

function Job(props) {
  let cname = useParams();
  const [clustername, setClustername] = useState(cname ? cname.clustername : undefined);
  const [jobNumber, setJobNumber] = useState(cname ? cname.job_number : undefined);
  const [success, setSuccess] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [runsTable, setRunsTable] = useState([]);
  const [headNodes, setHeadNodes] = useState([]);
  const [isLoadingJobsTable, setIsLoadingJobsTable] = useState(true);
  const [intervalId, setIntervalId] = useState(undefined);
  const [isHNTerminated, setIsHNTerminated] = useState(undefined);
  const [isHNStopped, setIsHNStopped] = useState(undefined);
  const [showTags, setShowTags] = useState(null);
  let [token, accessToken, getAccessToken] = useApiToken([]);

  useEffect(() => {
    let t = getLocalToken()
    if (t && t.AccessToken){
      token = t
      accessToken = t.AccessToken
    }
    else {
      const isAccessTokenExpired = !accessToken;
      if (isAccessTokenExpired) {
        getAccessToken();
      }
    }
  }, [token, accessToken, getAccessToken]);

  const getJobData = async (token) => {
    if (!token || !token.idToken) {
      setError("Can't load user login details")
      setSuccess(undefined)
      return;
    }
    else {
      setError(undefined)
      clearInterval(intervalId);
      axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASEURL}/viewjobs?job_number=${jobNumber}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token.idToken,
        },
      })
        .then((res) => {
          setError(undefined)
          res.data && res.data.runs && setRunsTable(res.data.runs)
          res.data && res.data.nodes && setHeadNodes(res.data.nodes)
          setIsLoadingJobsTable(false)
        })
        .catch((err) => {
          setIsLoadingJobsTable(false)
          if (err.response && err.response.data && err.response.data.statusCode === '401') {
            // setError(err.response.data.message)
            // localStorage.clear();
            // window.location.reload();
            console.log(err.response)
          }
          else {
            try {
              setError(err.response.data.message)
            } catch (error) {
              setError(err.message)
            }
          }
        });

    }
  };

  useEffect(() => {
    if (token) {
      getJobData(token);
    }
  }, [token]);


  const stop = (item) => {
    if (!token || !token.idToken) {
      setError("Can't load user login details")
      setSuccess(undefined)
      return;
    }
    console.log(item)
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASEURL}/stop_instance`,
      data: {
        InstanceId: item.InstanceId,
        region: "ap-southeast-2",
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: token.idToken,
      },
    })
      .then((res) => {
        console.log(res);
        getJobData(token);
        setError(undefined);
        setSuccess("Stoping Instance is inprogress");
        setIsHNStopped(true)
      })
      .catch((err) => {
        console.log(err);
        setIsHNStopped(undefined)
        setError(`Error : ${err.response.data.message}`);
        setSuccess(undefined);

      });
  }

  const destroy_cluster = (item) => {
    if (!token || !token.idToken) {
      setError("Can't load user login details")
      setSuccess(undefined)
      return;
    }
    console.log(item)
    let tagKey = "aws:cloudformation:stack-name"
    const cloudformation_clustername = (item.Tags).find((t) => t.Key === tagKey);
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASEURL}/termination`,
      data: {
        clustername: cloudformation_clustername && cloudformation_clustername.Value,
        InstanceId: item.InstanceId,
        jobNumber: jobNumber,
        region: "ap-southeast-2",
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: token.idToken,
      },
    })
      .then((res) => {
        console.log(res);
        setError(undefined);
        setSuccess("Cluster termination is inprogress");
        setIsHNTerminated(true)
      })
      .catch((err) => {
        console.log(err);
        setIsHNTerminated(undefined)
        setError(`Error : ${err.response.data.message}`);
        setSuccess(undefined);

      });
  }

  const handleTags = (e) => {
    setShowTags(!showTags);
  };

  return (
    <React.Fragment>
      <div className="viewpage_landing">
        <div className="row">
          <div className="col">
            {error && (
              <ModuleError message={error} variant="danger" />
            )}
            {success && (
              <ModuleError message={success} variant="success" />
            )}
          </div>
        </div>
        <div>
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className="col">
                  <h4>Runs for Job {jobNumber}</h4>
                </div>
              </div>
            </div>
            <div className="card-body">
              {!isEmpty(runsTable) && (
                <RunTable data={runsTable} props={props} jobNumber={jobNumber} clustername={clustername} callgetJobData={getJobData}></RunTable>
              )}
            </div >
          </div>


          {!isEmpty(headNodes) && headNodes.map((item, index) => (
            <div key={index}>
              <hr></hr>
              <div className="card" >
                  <div className="card-header">
                    <div className="row">
                      <div className="col">
                        <h4>HeadNode</h4>
                      </div>
                      <div className="termination_btn">
                        <Button
                          key="ShowTag"
                          variant="outline-success"
                          onClick={(e) => handleTags()}
                        // size="lg"
                        >
                          Show Tags
                        </Button>
                      </div>
                      <div className="termination_btn">
                        <Button
                          key="Stop"
                          variant="outline-warning"
                          disabled={isHNStopped || item.State === "stopped"}
                          onClick={(e) => stop(item)}
                        // size="lg"
                        >
                          Stop Instance
                        </Button>
                      </div>
                      <div className="termination_btn">
                        <Button
                          key="TerminateCL"
                          variant="outline-danger"
                          disabled={isHNTerminated}
                          onClick={(e) => destroy_cluster(item)}
                        // size="lg"
                        >
                          Terminate Cluster
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="card-body fontSize">
                    <h5 key="state">
                      State <span>{item.State}</span>
                    </h5>
                    <h5 key="state2">
                      InstanceId  <span>{item.InstanceId}</span>
                    </h5>
                    <h5 key="state4">
                      VpcId <span>{item.VpcId}</span>
                    </h5>
                    <h5 key="state3">
                      SubnetId  <span>{item.SubnetId}</span>
                    </h5>
                    <hr></hr>

                    {showTags && item.Tags.map((tag, ind) => (
                      <h5 key={ind}>
                        {tag.Key}  <span>{tag.Value}</span>
                      </h5>
                    ))}
                  </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </React.Fragment >
  );
}

export default Job;