import React, { useState } from "react";
import { ProgressBar, Modal, Button } from "react-bootstrap";
import moment from 'moment';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { useNavigate } from 'react-router-dom';
import { ModuleError } from "../components";
import axios from "axios";
import { getCred, useApiToken } from '../services/utils';


export const RunTable = ({ data, props, jobNumber, clustername, showSearch, searchProps, callgetJobData }) => {
    const navigate = useNavigate();
    const [success, setSuccess] = useState(undefined);
    const [error, setError] = useState(undefined);
    const [isLoadingJobsTable, setIsLoadingJobsTable] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [archiveEFS, setArchiveEFS] = useState(undefined)
    const [deleteCluster, setDeleteCluster] = useState(undefined)

    const runType = (run_type) => {
        switch (run_type) {
            case "1":
                return "Mesh&solve"
            case "2":
                return "Mesh"
            case "3":
                return "Solve"
            default:
                return "Mesh&solve"
        }
    }

    const priorityType = (priority) => {
        switch (priority) {
            case "1":
                return "Low"
            case "2":
                return "High"
            default:
                return "Low"
        }
    }

    const showModalData = (item, action, removeAll) => {
        if (removeAll) {
            setShowModal('All Data will be removed.');
            setDeleteCluster(item);
            setArchiveEFS(undefined);

        } else {
            setShowModal('Data will be removed from AWS EFS and archived in AWS S3');
            setArchiveEFS(item);
            setDeleteCluster(undefined);
        }
    };
    const handleArchive = async (archiveInput, deleteInput) => {
        try {
        
            let creds = await getCred();
            if (!creds || !creds.idToken) {
                setError("Can't load user login details")
                setSuccess(undefined)
                return;
            };
            setIsLoadingJobsTable(true)
            // getCred().then(creds => {

            // Perform the archive action here
            let data = {}
            if (deleteCluster) {
                data = {
                    CLUSTER_NAME: deleteCluster.cluster_name,
                    REGION: deleteCluster.region,
                    DELETE_S3: 'True'
                };

            } else if (archiveEFS) {
                data = {
                    CLUSTER_NAME: archiveEFS.cluster_name,
                    REGION: archiveEFS.region,
                };
            }
            axios({
                method: "POST",
                url: `${process.env.REACT_APP_BASEURL}/archive_simulation`,
                data: data,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: creds.idToken,
                },
            })
                .then((res) => {
                    console.log(res)
                    setError(undefined)
                    setSuccess(res.data)
                    callgetJobData(creds)
                    //   getJobData();
                })
                .catch((err) => {
                    console.log(err)
                    let msg = ''
                    msg = (err.response && err.response.data) ? err.response.data.message : err.message
                    setError(msg)
                    console.log(err.message)
                    // if (err.response && err.response.data && err.response.data.statusCode === '401') {
                    //     setError(err.response.data.message)
                    //     localStorage.clear();
                    //     window.location.reload();
                    // }
                    // else {
                    //     let msg = ''
                    //     msg = (err.response && err.response.data) ? err.response.data : err.message
                    //     setError(msg)
                    //     console.log(err.message)
                    // }
                })

            // Close the modal
            setShowModal(false)
            setArchiveEFS(undefined);
            setDeleteCluster(undefined);
            setIsLoadingJobsTable(false)
            // }).catch(e => {
            //     setError("Can't load user login details")
            //     setSuccess(undefined)
            //     setIsLoadingJobsTable(false)
            //     return;

            // })
        } catch (error) {
            setError("Can't load user login details")
            setSuccess(undefined)
            setIsLoadingJobsTable(false)
      
            return;
        }
    };

    const redirectToAnotherPage = (url) => {
        navigate(url);
    };

    function progressFormatter(cell, item) {
        return (
            <ProgressBar
                striped
                variant="info"
                // variant={item.variant}
                now={item.progresss_run || 0}
                label={`${item.progresss_run || 0}%`}
            />
        );
    }

    function detailsFormatter(cell, item) {
        return (
            <div >
                <Button
                    variant="outline-success"
                    size="sm"
                    onClick={() => redirectToAnotherPage(`/run/${item.cluster_name}`)}
                >
                    View
                </Button>
                <Button
                    variant="outline-info"
                    size="sm"
                    onClick={() => showModalData(item, 'ArchiveEFS')}
                >
                    Archive
                </Button>
                <Button
                    variant="outline-danger"
                    size="sm"
                    onClick={() => showModalData(item, 'DeleteAll', true)}
                >
                    Delete
                </Button>
            </div>
        );
    }

    function creationDateFormatter(cell, item) {
        const formattedDateTime = moment(item.creationTime, 'YYYYMMDDHHmmss').format('MMMM Do, YYYY h:mm:ss');
        return (
            <span>
                {formattedDateTime}
            </span>
        );
    }

    function durationDateFormatter(cell, item) {
        const duration = moment.duration(moment(item.lastUpdateTime).diff(moment(item.creationTime)))
        const hours = Math.floor(duration.asHours());
        const minutes = duration.minutes();
        return (
            <span>
                {hours} hours {minutes} minutes
            </span>
        );
    }

    function costFormatter(cell, item) {
        const duration = moment.duration(moment(item.lastUpdateTime).diff(moment(item.creationTime)))
        const totalSimulationHours = Math.ceil(duration.asHours());
        const cores = parseInt(item.number_of_core)
        const priority = parseInt(item.priority)
        let rate = undefined
        let numberOfComputeNode = 1

        if (cores === 16) {
            rate = priority === 1 ? 0.3 : 0.9;
        }
        else if (cores === 32) {
            rate = priority === 1 ? 0.6 : 1.7;
        }
        else if (cores === 64) {
            rate = priority === 1 ? 1.2 : 3.3;
        }
        else if (cores > 64) {
            numberOfComputeNode = cores / 64
            rate = priority === 1 ? numberOfComputeNode * 1.2 : numberOfComputeNode * 3.3;
        }

        const contingency = 1.2
        const cost = Math.ceil(totalSimulationHours * rate * contingency)

        return (
            <span>
                {cost} $
            </span>
        );
    }

    const defaultSorted = [{
        dataField: 'creationTime',
        order: 'desc'
    }];

    const options = {
        // pageStartIndex: 0,
        sizePerPage: 5,
        // hideSizePerPage: true,
        // hidePageListOnlyOnePage: true
    };

    const runExpandRow = {

        renderer: input => (
            <div className="expansionTextStyle">
                <div className="row" >
                    <div className="col" sm={12} md={6} xl={6}>
                        <h5>
                            Run Type <span>{runType(input.run_type)}</span>
                        </h5>
                        <h5>
                            No. Cores <span>{input.number_of_core}</span>
                        </h5>
                        <h5>
                            Priority <span>{priorityType(input.priority)}</span>
                        </h5>
                        <h5>
                            Uplploaded File  <span>{input.file_name}</span>
                        </h5>
                        {input.keep_cluster ? (
                            <h5>
                                Keep Cluster
                            </h5>
                        ) : (
                            <h5>
                                Terminate Cluster
                            </h5>
                        )}
                        {input.max_run_time && (
                            <h5>
                                Max Run Time {input.max_run_time} Hours
                            </h5>
                        )}
                    </div>
                    <div className="col" sm={12} md={6} xl={6}>
                        <h5>
                            Status  <span>{input.status}</span>
                        </h5>
                        <h5>
                            Progresss <span>{input.progresss_run}%</span>
                        </h5>
                    </div>
                </div>

            </div>

        ),
        // showExpandColumn: true,
        // expandByColumnOnly: true,
        onlyOneExpanding: true,
        onExpand: (row, isExpand, rowIndex, e) => {
            // console.log(row.id);
            // console.log(isExpand);
            // console.log(rowIndex);
            // console.log(e);
        },
    };

    const runColumns = [{
        dataField: 'progresss_run',
        text: 'Progress',
        sort: true,
        formatter: progressFormatter,
    }, {
        dataField: 'run_name',
        text: 'Run Name',
        sort: true,
        filter: textFilter()
    }, {
        dataField: 'job_number',
        text: 'Job No.',
        filter: textFilter()
    }, {
        dataField: 'user',
        text: 'User',
        sort: true,
        filter: textFilter()
    },
    {
        dataField: 'creationTime',
        text: 'Creation Date',
        sort: true,
        formatter: creationDateFormatter
    },
    {
        dataField: 'duration',
        text: 'Simulation Duration',
        sort: true,
        formatter: durationDateFormatter
    },
    {
        dataField: 'cost',
        text: 'Estimated Cost',
        sort: true,
        formatter: costFormatter
    },
    {
        dataField: 'Details',
        text: 'Actions',
        formatter: detailsFormatter
    },];

    const { SearchBar } = Search;

    return (
        <React.Fragment>
            <div>
                <div className="row">
                    <div className="col" sm={12}>
                        {error && (
                            <ModuleError message={error} variant="danger" />
                        )}
                        {success && (
                            <ModuleError message={success} variant="success" />
                        )}
                    </div>
                </div>
                <div>
                    <Modal show={showModal} onHide={() => setShowModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirmation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Are you sure you want to proceed?</p>
                            <p>{showModal}</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowModal(false)}>
                                Cancel
                            </Button>
                            <Button variant="primary" onClick={(e) => handleArchive(archiveEFS, deleteCluster)}>
                                Confirm
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    {isLoadingJobsTable && (
                        <arc-spinner className="apinnerStyle">loading...</arc-spinner>
                    )}
                    {(showSearch) ? (

                        <ToolkitProvider
                            keyField='cluster_name'
                            data={data}
                            columns={runColumns}
                            search
                        >
                            {
                                props => (
                                    <div>
                                        <h3>Search Through Run Name, Job Number, or User</h3>
                                        <SearchBar {...props.searchProps} />
                                        <hr />
                                        <BootstrapTable
                                            bootstrap4
                                            keyField='cluster_name'
                                            data={data}
                                            columns={runColumns}
                                            headerWrapperClasses="headerCells"
                                            pagination={paginationFactory(options)}
                                            expandRow={runExpandRow}
                                            filter={filterFactory()}
                                            defaultSorted={defaultSorted}
                                            // bordered={false}
                                            striped
                                            {...props.baseProps}

                                        />
                                    </div>
                                )
                            }
                        </ToolkitProvider>

                    ) : (
                        <BootstrapTable
                            bootstrap4
                            keyField='cluster_name'
                            data={data}
                            columns={runColumns}
                            headerWrapperClasses="nestedHeaderCells"
                            pagination={paginationFactory(options)}
                            expandRow={runExpandRow}
                            filter={filterFactory()}
                            defaultSorted={defaultSorted}
                            // bordered={false}
                            striped
                        />
                    )}

                </div>
            </div>
        </React.Fragment>
    )
}