import React from "react";
import FujinLogo from "./FujinLogo.png"

function Home(props) {  
  return (
    <React.Fragment>
      <div className="home_landing">
        <div className="paddingStyle">
          <div className="row" >
            <div className="col-md-10 col-xl-10">
              <div className="text_center paddingStyle">
                <img src={FujinLogo} alt="FujinLogo" ></img>
                <h3 className="text_center red_text">Let Fujin, God of Wind, take you to the clouds.</h3>
              </div>
              <div className="col paddingStyle">
                <h4>Welcome to Fujin</h4>
                <h4>A web application for running CFD simulations on cloud</h4>
                <h4>Version 1.0  </h4>
              </div>
            </div>
          </div>
          <div>
            <div className="row fontSize">
              <div className="col-sm-12 col-md-12 col-xl-12">

                <p>
                  Are you looking for a fast and easy way to run your CFD cases on the cloud? Do you want to get live updates and monitor your results online? Do you need a powerful and flexible tool that can handle complex and large-scale simulations? If you answered yes to any of these questions, then Fujin is the perfect solution for you!
                </p>
                <p>
                  Fujin is a web application that allows you to run OpenFOAM cases in AWS cloud with live status updates and live monitoring plots including residuals, minMax and probes function objects (if defined). The updates run every 20 seconds and all the logs become available to download as soon as the files are created. Fujin can utilize up to 1000 cores<sup><a href="#footnote-1">1</a></sup>. It uses OpenFOAM v2206 and requires a bash script named “run.sh” containing all executables and commands to run in the cloud<sup><a href="#footnote-1">1</a></sup>. There is an option to terminate the job at any point and an option to request a cloud-based graphic virtual machine to visualize the results with Paraview<sup><a href="#footnote-1">1</a></sup>.
                </p>
                <p>
                  The source code and documentation for the back-end and frond-end infrastructure is available for developers in the Github from <a href="https://github.com/arup-group/fujin_hpc">this link.</a>
                </p>
                <p>
                  An estimation of the cloud costs per hour as a rule of thumb is given in the table below. The cost will be charged into the job number provided by the user. It is user’s responsibility to keep track of the costs.
                </p>
                <div className="paddingStyle">
                  <table className="table table-md table-striped table-bordered table-hover" >
                    <thead>
                      <tr>
                        <th>No. Cores</th>
                        <th>High-Priority($ USD per hour) </th>
                        <th>Low-Priority($ USD per hour) </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>16</td>
                        <td>0.9</td>
                        <td>0.3</td>
                      </tr>
                      <tr>
                        <td>32</td>
                        <td>1.7</td>
                        <td>0.6</td>
                      </tr>
                      <tr>
                        <td>64</td>
                        <td>3.3</td>
                        <td>1.2</td>
                      </tr>
                      <tr>
                        <td>n*64</td>
                        <td>n*3.3</td>
                        <td>n*1.2</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p>
                  Based on the previous projects in the cloud, we have developed a table with a few scenarios as below for an indicative cloud costs per scenario.
                </p>

                <div className="paddingStyle">
                  <table className="table table-md table-striped table-bordered table-hover">
                    <thead>
                      <tr>
                        <th>Scenario</th>
                        <th>1</th>
                        <th>2</th>
                        <th>3</th>
                        <th>4</th>
                        <th>5</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Mesher</td>
                        <td>blockMesh snappyHexMesh </td>
                        <td>blockMesh snappyHexMesh </td>
                        <td>blockMesh snappyHexMesh </td>
                        <td>blockMesh snappyHexMesh </td>
                        <td>blockMesh snappyHexMesh </td>
                      </tr>
                      <tr>
                        <td>Solver</td>
                        <td>simpleFoam </td>
                        <td>simpleFoam </td>
                        <td>simpleFoam </td>
                        <td>buoyantBoussinesqSimpleFoam  </td>
                        <td>buoyantBoussinesqSimpleFoam  </td>
                      </tr>
                      <tr>
                        <td>Mesh Quality </td>
                        <td>Max non-ortho = 68.98, (0 cell non-orthogonal) Max Skew = 14.22 (2005 cell)  </td>
                        <td>Max non-ortho = 74.77 (6 cell), Max Skew = 19.12 (2587 cell)  </td>
                        <td>Max non-ortho = 78.26, (7 cell) Max Skew = 9.59 (1311 cell)  </td>
                        <td>Max non-ortho = 78.67 (21 cell), Max Skew = 7.6 (38 cell)  </td>
                        <td>Max non-ortho = 74.69 (6 cell),  Max Skew = 9.62 (25 cell)  </td>
                      </tr>
                      <tr>
                        <td>No. of cores </td>
                        <td>128 </td>
                        <td>128 </td>
                        <td>256 </td>
                        <td>64 </td>
                        <td>256 </td>
                      </tr>
                      <tr>
                        <td>No. of cells (mill) </td>
                        <td>9 </td>
                        <td>24.47 </td>
                        <td>92.26 </td>
                        <td>20 </td>
                        <td>58.44 </td>
                      </tr>
                      <tr>
                        <td>No. of cells per core (thousands) </td>
                        <td>70,000 </td>
                        <td>40,000 </td>
                        <td>20,000 </td>
                        <td>320,000 </td>
                        <td>14,000 </td>
                      </tr>
                      <tr>
                        <td>Mesh Time (minutes) </td>
                        <td>7 </td>
                        <td>54 </td>
                        <td>82 </td>
                        <td>33 </td>
                        <td>36 </td>
                      </tr>
                      <tr>
                        <td>Solution time (sec/iteration) </td>
                        <td>1 </td>
                        <td>6 </td>
                        <td>4 </td>
                        <td>12 </td>
                        <td>16 </td>
                      </tr>
                      <tr>
                        <td>Total simulation time  (minutes) </td>
                        <td>73 (for 4000 iterations)  </td>
                        <td>546 (for 4000 iterations)  </td>
                        <td>289 (for 3000 iterations)  </td>
                        <td>417 (for 2000 iterations)  </td>
                        <td>510 (for 3000 iterations)  </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <p>
                  This is the initial stage of the Fujin development, our innovative platform for CFD cloud computing. We have received a lot of valuable feedbacks from our early adopters, and we are working hard to integrate them for the next release. Some of the exciting features that we are planning to add are: a debug/developer mode with additional controls, a web-based visualization tool, a multi runs and multi jobs option, an SSH capability and a cost explorer page.
                </p>
                <p>
                  We would love to hear from you if you have any questions or feedbacks about Fujin. Please contact Sina Hassanli and let us know what you think.
                </p>
              </div>
            </div>
          </div>
        </div>
        <footer className="font20Size">
          <ol>
            <li id="footnote-1">
              <p>Fujin uses an AWS service called ParallelCluster which in principle is similar to a typical on-prim cluster configuration with enables multi-core and multi-mode processing. It uses MPI module for parallel-processing and can utilize as many as 1000 cores (Fujin Maximum core limit).
              </p>
            </li>
            <li id="footnote-2">
              <p>
                Fujin is compatible with the Moth Grasshopper plugin so the output from Moth can be directly uploaded. The bash script does not require any scheduler header such as slurm or PBS. The next version of Moth will have a Fujin component for full compatibility and additional functionality.
              </p>
            </li>
            <li id="footnote-3">
              <p>DCV software is required to log into the virtual machine using the provided session ID, username and password.</p>
            </li>
          </ol>
        </footer>
      </div>
    </React.Fragment>
  );
}

export default Home;